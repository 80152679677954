import React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import thumb1 from '../../../images/recently/recent1.jpg'
import ChannelInfo from '../ChannelInfo';

export default function ContentDash() {
    const [selectedOption, setSelectedOption] = useState('video');

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.id);
    };
  return (
    <>
        <Row>
                        <Col sm={7} lg={8} xl={9}>
                        <div className='contentFilterwrapper'>
                            <span>Channel content</span>
                            <div className='contentFilter'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="video" checked={selectedOption === 'video'}
                                        onChange={handleOptionChange} />
                                    <label className="form-check-label" for="video">
                                        Video
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="reels" checked={selectedOption === 'reels'} 
                                        onChange={handleOptionChange} />
                                    <label className="form-check-label" for="reels">
                                        Reels
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="videolists" style={{ display: selectedOption === 'video' ? 'block' : 'none' }}>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="videcheckall"/>
                                                    <label className="form-check-label" for="videcheckall"></label>
                                                </div>
                                            </th>
                                            <th>Video</th>
                                            <th>Visibility</th>
                                            <th>Restriction</th>
                                            <th>Date</th>
                                            <th>Views</th>
                                            <th>Comments</th>
                                            <th>Likes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="videcheck1"/>
                                                    <label className="form-check-label" for="videcheck1"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src={thumb1} loading='lazy' className='img-fluid'/>
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="videcheck2"/>
                                                    <label className="form-check-label" for="videcheck2"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src={thumb1} loading='lazy' className='img-fluid'/>
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="videcheck3"/>
                                                    <label className="form-check-label" for="videcheck3"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src={thumb1} loading='lazy' className='img-fluid'/>
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="videcheck4"/>
                                                    <label className="form-check-label" for="videcheck4"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src={thumb1} loading='lazy' className='img-fluid'/>
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="videcheck5"/>
                                                    <label className="form-check-label" for="videcheck5"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src={thumb1} loading='lazy' className='img-fluid'/>
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>                                    <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="videcheck6"/>
                                                    <label className="form-check-label" for="videcheck6"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src={thumb1} loading='lazy' className='img-fluid'/>
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="videcheck7"/>
                                                    <label className="form-check-label" for="videcheck7"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src={thumb1} loading='lazy' className='img-fluid'/>
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="videolists reels" style={{ display: selectedOption === 'reels' ? 'block' : 'none' }}>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="reelall" />
                                                    <label className="form-check-label" for="reelall"></label>
                                                </div>
                                            </th>
                                            <th>Reels</th>
                                            <th>Visibility</th>
                                            <th>Restriction</th>
                                            <th>Date</th>
                                            <th>Views</th>
                                            <th>Comments</th>
                                            <th>Likes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="reel1" />
                                                    <label className="form-check-label" for="reel1"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src="https://snagfilms-a.akamaihd.net/dd078ff5-b16e-45e4-9723-501b56b9df0a/images/2021/08/12/1628770051794_unoloukikposter1920x10804thepcopy_16x9Images.jpg?impolicy=resize&w=450&h=253.125" loading='lazy' className='img-fluid' />
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="reel2" />
                                                    <label className="form-check-label" for="reel2"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src="https://snagfilms-a.akamaihd.net/dd078ff5-b16e-45e4-9723-501b56b9df0a/images/2021/08/12/1628770051794_unoloukikposter1920x10804thepcopy_16x9Images.jpg?impolicy=resize&w=450&h=253.125" loading='lazy' className='img-fluid' />
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="reel3" />
                                                    <label className="form-check-label" for="reel3"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src="https://snagfilms-a.akamaihd.net/dd078ff5-b16e-45e4-9723-501b56b9df0a/images/2021/08/12/1628770051794_unoloukikposter1920x10804thepcopy_16x9Images.jpg?impolicy=resize&w=450&h=253.125" loading='lazy' className='img-fluid' />
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="reel4" />
                                                    <label className="form-check-label" for="reel4"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src="https://snagfilms-a.akamaihd.net/dd078ff5-b16e-45e4-9723-501b56b9df0a/images/2021/08/12/1628770051794_unoloukikposter1920x10804thepcopy_16x9Images.jpg?impolicy=resize&w=450&h=253.125" loading='lazy' className='img-fluid' />
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="reel5" />
                                                    <label className="form-check-label" for="reel5"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src="https://snagfilms-a.akamaihd.net/dd078ff5-b16e-45e4-9723-501b56b9df0a/images/2021/08/12/1628770051794_unoloukikposter1920x10804thepcopy_16x9Images.jpg?impolicy=resize&w=450&h=253.125" loading='lazy' className='img-fluid' />
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>                                    <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="reel6" />
                                                    <label className="form-check-label" for="reel6"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src="https://snagfilms-a.akamaihd.net/dd078ff5-b16e-45e4-9723-501b56b9df0a/images/2021/08/12/1628770051794_unoloukikposter1920x10804thepcopy_16x9Images.jpg?impolicy=resize&w=450&h=253.125" loading='lazy' className='img-fluid' />
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="reel7" />
                                                    <label className="form-check-label" for="reel7"></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='tableVthumb'>
                                                    <div className='vthumbimg'>
                                                        <img src="https://snagfilms-a.akamaihd.net/dd078ff5-b16e-45e4-9723-501b56b9df0a/images/2021/08/12/1628770051794_unoloukikposter1920x10804thepcopy_16x9Images.jpg?impolicy=resize&w=450&h=253.125" loading='lazy' className='img-fluid' />
                                                    </div>
                                                    <div className='vthumbinfo'>
                                                        <b>radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17</b>
                                                        <p>Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js? We've learned...</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Public</td>
                                            <td>None</td>
                                            <td>
                                                <b>Jun21, 2024</b>
                                                <small>Published</small>
                                            </td>
                                            <td>469</td>
                                            <td>4</td>
                                            <td>
                                                <b>100%</b>
                                                <small>18 Likes</small>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </Col>
                        <Col sm={5} lg={4} xl={3}>
                            <div className='channelinfoBar'>
                                <ChannelInfo/>
                            </div>
                        </Col>
                    </Row>
    </>
  )
}
