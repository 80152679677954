import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { TiTick } from "react-icons/ti";
import { IoTrophyOutline } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import bannerMap from '../../images/banner-map.png';
import viewsShape from '../../images/viewsShape.png';

import './paynow.css';

export default function Paynow() {
    const [activeButton1, setActiveButton1] = useState(0); // Default to the first button
    const [formData, setFormdata] = useState({
        price: "195",
        month: "90 Days"
    });

    const handlePremiumClick = (index) => {
        setActiveButton1(index);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        const month = e.target.getAttribute('data-month');

        setFormdata({
            ...formData,
            price: value,
            month: month,
        });
    };

    const [divState, setdivState] = useState(false);
    const contestHandleClick = () => {
        setdivState(!divState);
    };

    const [paymentagree, setPaymentagree] = useState(false);

    const addedvalue = 275
    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setPaymentagree(isChecked);
        // If checked, update the price to ₹275
        if (isChecked) {
            setFormdata({
                ...formData,
                price: (195+ addedvalue),
            });
        } else {
            // Revert to the original price if unchecked
            setFormdata({
                ...formData,
                price: "195",
            });
        }
    };

    return (
        <>
            <section className='paynow'>
                <div className='viewsShape'><img src={viewsShape} /></div>
                <div className='bannerMap'><img src={bannerMap} alt='' /></div>
                <Container>

                    <Row className='justify-content-center'>
                        <Col sm={12} lg={8}>
                            <div className='payNowWrap'>
                                <div className='paynowwrapInner'>
                                <div className="subscribe_check_box">
                                    <div className={`premium ${activeButton1 === 0 ? 'activeradio' : ''}`} onClick={() => handlePremiumClick(0)}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" data-month="90 Days" onChange={handleChange} id="flexRadioDefault4" value="195" checked={formData.price == "195"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault4">
                                                <div className="premium_hd">
                                                    Danveer
                                                </div>
                                                <div className="price_month">
                                                    <span className="pAmount">₹195</span>
                                                    <small>3 Months</small>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className={`premium super ${activeButton1 === 1 ? 'activeradio' : ''}`} onClick={() => handlePremiumClick(1)}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" data-month="90 Days" onChange={handleChange} id="flexRadioDefault5" value="0" checked={formData.price == "0"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault5">
                                                <div className="premium_hd">
                                                    Yodha
                                                </div>
                                                <div className="price_month">
                                                    <span className="pAmount">₹0</span>
                                                    <small>3 Months</small>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {activeButton1 === 0 && (
                                    <div className='packageincludes'>
                                        <div className='packageLabel'>Danveer Includes</div>

                                        <Table striped bordered hover id="subscribe_table" className='addnew'>
                                            <tr>
                                                <th></th>
                                                <th>Availability</th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Donate ₹195
                                                    <br />
                                                    <span className="specials">Donate Rs.195/- to Transmitto development foundation to after that you become a donor</span>
                                                </td>
                                                <td><TiTick /></td>
                                            </tr>
                                            <tr>
                                                <td>3 month free subscription
                                                    <br />
                                                    <span className="specials">Allow admission 3 month free subscription free in support india apps</span>
                                                </td>
                                                <td><TiTick /></td>
                                            </tr>
                                            <tr>
                                                <td>Upload video income gain</td>
                                                <td><TiTick /></td>
                                            </tr>
                                            <tr>
                                                <td>Self employment proposal</td>
                                                <td><TiTick /></td>
                                            </tr>
                                        </Table>

                                        <Row className='align-items-end'>
                                            <Col sm={12} lg={6}>
                                                <div className="whatDoContent">
                                                    <b>Create all types of videos without the fuss</b>
                                                    <h2>What do you have to do</h2>
                                                </div>
                                            </Col>
                                            <Col sm={12} lg={6}>
                                                <ul className="payListing">
                                                    <li>Make short video</li>
                                                    <li>Collect likes on your video / shorts</li>
                                                </ul>
                                            </Col>
                                        </Row>

                                        <div className={paymentagree ? 'addedbenefitsWrap activated' : 'addedbenefitsWrap'}>
                                            <div className={divState ? 'addedbenefits expand' : 'addedbenefits'}>
                                                <b className="addedbenefitsText" onClick={contestHandleClick}>Let's join a Contest</b>
                                                <div className="contestPrize">
                                                    <Row>
                                                        <Col sm={4}>
                                                            <div className="prizeDetail" data-no="01">
                                                                <div className="prizeIcon">
                                                                    <IoTrophyOutline />
                                                                </div>
                                                                <p>1st Top will get 1 Lakh</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <div className="prizeDetail" data-no="02">
                                                                <div className="prizeIcon">
                                                                    <IoTrophyOutline />
                                                                </div>
                                                                <p>2nd Top will get Sewing-Machine/Person</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <div className="prizeDetail" data-no="03">
                                                                <div className="prizeIcon">
                                                                    <IoTrophyOutline />
                                                                </div>
                                                                <p>3rd Top will get cottage industry materials</p>
                                                            </div>
                                                        </Col>

                                                        <Col sm={12}>
                                                            <p className="moreprize">You may also win (A)<b>LED Bulbs Kit</b> (B)<b>Soap die & frame</b> (C)<b>Handwash & Dishwash Setup</b></p>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <div className="agree">
                                                                <div className={paymentagree ? 'form-check activated' : 'form-check'}>
                                                                    <input className="form-check-input" type="checkbox" value="" id="contestpayment" onChange={handleCheckboxChange} />
                                                                    <label className="form-check-label" htmlFor="contestpayment">
                                                                        For this Benefits pay <b>₹{addedvalue}</b> more
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {activeButton1 === 1 && (
                                    <div className="packageincludes">
                                        <div className='packageLabel'>Yodha Includes</div>
                                        <Table striped bordered hover id="subscribe_table" className='addnew'>
                                            <tr>
                                                <th></th>
                                                <th>Availability</th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Donate ₹0
                                                    <br />
                                                    <span className="specials">In Yodha scheme Membership amount ₹275.00 will be paid by Support India Digital Platform as <b className='text-white'>Bonus</b></span>
                                                </td>
                                                <td><TiTick /></td>
                                            </tr>
                                            <tr>
                                                <td>3 month free subscription
                                                    <br />
                                                    <span className="specials">Allow admission 3 month free subscription free in support india apps</span>
                                                </td>
                                                <td><TiTick /></td>
                                            </tr>
                                            <tr>
                                                <td>Upload video income gain</td>
                                                <td><TiTick /></td>
                                            </tr>
                                            <tr>
                                                <td>Self employment proposal</td>
                                                <td><TiTick /></td>
                                            </tr>
                                        </Table>
                                    </div>
                                )}

                                <div className="package_btn">
                                    <div className="amountSelected">
                                        <small style={{ color: 'grey' }}>Net Pay</small>
                                        <div className="amount mt-2">₹{formData.price} for <small className="months">{formData.month}</small></div>
                                    </div>
                                    <button className="btn primary-btn" title="Play Now">Continue <IoIosArrowForward /></button>
                                </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    );
}
