import React from 'react';
import '../vijaypath/vijaypath.css';

import {useParams} from 'react-router-dom';
import catbg from '../../images/catbg.jpg';
import Breadcumb from '../../components/breadcumb/Breadcumb';
import { Col, Row } from 'react-bootstrap';
import SectionTitle from '../../includes/sectionTitle/SectionTitle';

export default function Offers() {

    //const {catName} = useParams();

    const offerdata = [{
        offerImg : "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-sewing-machine-diy-flaticons-lineal-color-flat-icons-3.png",
        offerName: "SEWING MACHINE",
        offerTag: "Offer 01"
    },
    {
        offerImg : "https://img.icons8.com/officel/64/scooter.png",
        offerName: "SCOOTY",
        offerTag: "Offer 02"
    },
    {
        offerImg : "https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-led-bulb-diy-flaticons-lineal-color-flat-icons.png",
        offerName: "LED BULB SS INDUSTRY",
        offerTag: "Offer 03"
    },
]

  return (
    <>
        <section className='catBanner' style={{backgroundImage: `url(${catbg})`}}>
          <div className='container-fluid'>
            <Row>
              <Col sm={12}><Breadcumb pageTitle={"Offer"} /></Col>
            </Row>
          </div>
        </section>
        <section className='offerSec'>
            <div className='container'>
                <Row>
                    <Col sm={12}>
                        <SectionTitle stitle="Offers"/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} md={4}>
                        <div className='offerItem'>
                            <b className='offerTitle'>YOU CAN BECOME A WINNER!</b>
                            <div className='offerImg'>
                                <img width="64" height="64" src="https://img.icons8.com/external-justicon-flat-justicon/64/external-graduation-elearning-and-education-justicon-flat-justicon-1.png" alt="external-graduation-elearning-and-education-justicon-flat-justicon-1"/>
                            </div>
                            <div className='offerInfo'>
                                <b>₹ 10,00,000</b>
                                <small>Education Fund</small>
                            </div>
                        </div>
                    </Col>
                </Row>

                <p className='sectionBreak'><span>You may also Win</span></p>

                <Row>
                    {
                        offerdata.map((offerValue)=>{
                            console.log(offerValue)
                            const {offerImg, offerName, offerTag } = offerValue;
                            return (
                                <Col sm={6} md={4} lg={3}>
                                    <div className='offerItem'>
                                        <div className='offerImg'>
                                        <img width="64" height="64" src={offerImg} alt="external-led-bulb-diy-flaticons-lineal-color-flat-icons"/>
                                        </div>
                                        <div className='offerInfo'>
                                            <b>{offerName}</b>
                                            <small>{offerTag}</small>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </section>
    </>
  )
}
