import thumbImg1 from '../../../images/recently/recent1.jpg'

const CommentData = [{
        id : "comment00",
        viewersEmail: "@saptarshiroy3704",
        ViewrsImg: "R",
        viewrsComment : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using",
        thumbImg: thumbImg1,
        thumbTitle : "radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17",
        thumbDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    },
    {
        id: "comment01",
        viewersEmail: "@saptarshiroy3704",
        ViewrsImg: "R",
        viewrsComment : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form",
        thumbImg: thumbImg1,
        thumbTitle : "radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17",
        thumbDescription: 'Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js?'
    },
    {
        id: "comment02",
        viewersEmail: "@saptarshiroy3704",
        ViewrsImg: "R",
        viewrsComment : "Donate: If you use this site regularly and would like to help keep ",
        thumbImg: thumbImg1,
        thumbTitle : "radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17",
        thumbDescription: "when an unknown printer took a galley of type and scrambled it to make a type specimen book. "
    },
    {
        id: "comment03",
        viewersEmail: "@rajib990",
        ViewrsImg: "R",
        viewrsComment : "the table will behave normally and not scroll horizontally.",
        thumbImg: thumbImg1,
        thumbTitle : "radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17",
        thumbDescription: 'Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js?'
    },
    {
        id: "comment04",
        viewersEmail: "@saptarshiroy3704",
        ViewrsImg: "R",
        viewrsComment : "the table will behave normally and not scroll horizontally.",
        thumbImg: thumbImg1,
        thumbTitle : "radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17",
        thumbDescription: 'Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js?'
    },
    {
        id: "comment05",
        viewersEmail: "@saptarshiroy3704",
        ViewrsImg: "R",
        viewrsComment : "the table will behave normally and not scroll horizontally.",
        thumbImg: thumbImg1,
        thumbTitle : "radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17",
        thumbDescription: 'Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js?'
    },
    {
        id: "comment06",
        viewersEmail: "@saptarshiroy3704",
        ViewrsImg: "R",
        viewrsComment : "the table will behave normally and not scroll horizontally.",
        thumbImg: thumbImg1,
        thumbTitle : "radio button, checkbox in React | In Hindi | React Form Part - 3 | Reactjs Tutorial - #17",
        thumbDescription: 'Using a Radio Button Group: Radio buttons let a user choose a single option from a list of multiple radio buttons and submit its value. How do you use checkboxes in React.js?'
    },
]

export default CommentData;