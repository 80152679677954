import React, { useState, useEffect   } from 'react'
import { Col, Row } from 'react-bootstrap';
import { FaRegCalendarPlus } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import useClipboard from "react-use-clipboard";
import { MdContentCopy } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";

export default function Step4({uploadfile}) {

  const [download, setdownload] = useState("Private");

  function onChangeValue(event) {
    setdownload(event.target.value);
  }

      // const [buttonIcon, setBtnIcon] =useState(false);

      const [textTocopy, setTexttoCopy] = useState('');
      const [isCopied, setCopied] = useClipboard(textTocopy);
  
      const copyfunction = () => {
          const videoLinkText = document.getElementById('videoLink').innerText;
          setTexttoCopy(videoLinkText); // Update the state first
      };
  
      useEffect(() => {
          if (textTocopy) {
              setCopied(); // Copy to clipboard after the state is updated
              // alert(textTocopy);
          }
      }, [textTocopy, setCopied]);
  return (
    <>
      <div className='step4Wrap'>
        <Row>
          <Col sm={12} md={8}>
            <p>Choose when to publish and who can see your video</p>
            <div className='stepCard4'>
              <div className='cardHeader'>
                <strong>Save & Publish</strong>
                <p>Make your video <b>Public</b>, <b>Unlisted</b> or <b>Private</b></p>
              </div>
              <div className='radioWraper' onChange={onChangeValue}>
                <div className='StepradioItem'>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" value="Private" id="Private" checked={download === "Private"} />
                    <label class="form-check-label" for="Private">
                      <b>Private</b>
                      <small>Only you and people you choose can watch your video</small>
                    </label>
                  </div>
                </div>
                <div className='StepradioItem'>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" value="Unlisted" id="Unlisted" checked={download === "Unlisted"} />
                    <label class="form-check-label" for="Unlisted">
                      <b>Unlisted</b>
                      <small>Anyone with the link can watch your video</small>
                    </label>
                  </div>
                </div>
                <div className='StepradioItem'>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" value="Public" id="Public" checked={download === "Public"} />
                    <label class="form-check-label" for="Public">
                      <b>Public</b>
                      <small>Everyone can watch your video</small>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className='stepCard'>
                <div className='stepCardinfo'>
                  <div className='stepIcon'><FaRegCalendarPlus/></div>
                  <div className='stepcardText'>
                    <b>Schedule</b>
                    <p>Select date to  make your video Public</p>
                  </div>
                </div>
                <Link to={""} className='SetScheduleBtn'>Set Schedule</Link>
              </div>
          </Col>
          <Col sm={12} md={4}>
          <div className='previewCard'>
              <div className='previewImg'>
                <video src={uploadfile} autoPlay='true' controls style={{ width: "100%",  display: `block` }}></video>
              </div>
              <div className='previewDetails'>
                <div className='preInfo'>
                  <small>Video Link</small>
                  <strong className='videoLink' id='videoLink'>fbsdnbfjs</strong>
                  <button className={isCopied === false ? 'copyBtn ripple' : 'copyBtn ripple success'} onClick={copyfunction}>{isCopied === false ? <MdContentCopy /> : <IoMdCheckmark />}</button>
                  {/* <button className='copyBtn ripple' onClick={copyHandleClick}>{buttonIcon === false ? <MdContentCopy /> : <IoMdCheckmark />}</button> */}
                </div>

                <div className='preInfo mt-3'>
                  <small>Filename</small>
                  <strong>{uploadfile}</strong>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
