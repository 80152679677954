import React, { useState, useEffect,useRef } from 'react';
import { useParams } from 'react-router-dom';

import ReactPlayer from 'react-player'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

import '../singlepage/singlepagereel.css'


// import { Link } from 'react-router-dom';

import videofile from '../../videos/3997798-uhd_2160_4096_25fps.mp4'
import videofile2 from '../../videos/mixkit-abstract-video-of-a-man-with-heads-like-matrushka-32647-hd-ready.mp4'
import videofile3 from '../../videos/mixkit-dynamic-animation-of-the-head-of-a-screaming-man-32645-hd-ready.mp4'



import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { FaThumbsUp } from "react-icons/fa6";
import { FaThumbsDown } from "react-icons/fa";
import { MdInsertComment } from "react-icons/md";
import { FaShareFromSquare } from "react-icons/fa6";
import { IoMdMore } from "react-icons/io";
import { LuFilter } from "react-icons/lu";

import videodata from '../../data/reels-reels.json'

export default function Singlepagereel() {
    const [isPlaying, setIsPlaying] = useState(null);
    const { videoId } = useParams();
    
    const [btnStatus, setbtnStatus] = useState(false);

    const likeHandleChange = () => {
        setbtnStatus(!btnStatus)
    }

    const [dbtnStatus, setdbtnStatus] = useState(false);
    const dlikeBtnhandeler = () => {
        setdbtnStatus(!dbtnStatus)
    }

    const [commentStatus, setcommentStatus] = useState(false);


    const [showCmnt, setshowCmnt] = useState(false);
    const handleShow = () => {
        setshowCmnt(true);
        setcommentStatus(!commentStatus)
    };

    const handleClose = () => {
        setshowCmnt(false);
        setcommentStatus(!commentStatus)
    };

    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const filterRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        // Add event listener for detecting clicks outside the component
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const dd = () => {
        alert("demo alert")
    }
    return (
        <>
            <section className='banner_sec'>
                <Container>
                    <Row className="">

                        <Col md={{ span: 10, offset: 1 }}>
                            <Swiper
                                direction={'vertical'}
                                
                                autoHeight={true}
                                autoplay={false}
                                watchSlidesProgress={true}
                                updateOnWindowResize={true}
                                modules={[Mousewheel, Navigation]}
                                mousewheel={true}
                                spaceBetween={20}
                                // slidesPerView={2}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                centeredSlides={true}
                                loop={true}
                                onSlideChange={() => {
                                    setIsPlaying(null);
                                }}
                                breakpoints={{
                                    // when window width is >= 640px
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    // when window width is >= 768px
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                }}
                            >
                                {videodata.map((data) => (
                                    <SwiperSlide>
                                        <div className='reelSlideItem'>
                                            <div className='sliderItem'>
                                                <div className='slide_img'>
                                                    <ReactPlayer
                                                        loop={true}
                                                        tabIndex="-1" 
                                                        data-no-fullscreen="true" 
                                                        controlsList="nodownload"
                                                        className='slide_vdo'
                                                        
                                                        pip={false}
                                                        key={data.id}
                                                        url={[
                                                            { src: data.videoLink },

                                                        ]}
                                                        controls={true}
                                                        onPlay={() => {
                                                            setIsPlaying(data.id);
                                                        }}
                                                        playing={isPlaying === data.id}
                                                    />


                                                </div>

                                            </div>
                                            <ul className='reelOption'>
                                                <li>
                                                    <button className={`reelBtn ${btnStatus === true ? 'active' : ''}`} id='likeBTn' title='Like' onClick={likeHandleChange}><FaThumbsUp /></button>
                                                    
                                                    <span class="text-center">{data.videoViews}</span>
                                                </li>
                                                {/* <li>
                                                    <button className={`reelBtn dlikeBTn ${dbtnStatus === true ? "active" : ""}`} id='dlikeBTn' title='Dislike' onClick={dlikeBtnhandeler}><FaThumbsDown /></button>
                                                    
                                                    <span>Dislike</span>
                                                </li> */}
                                                {/* <li>
                                                    <button type='button' className={`reelBtn ${commentStatus === true ? 'active' : ''}`} id='cmntBTn' title='Comments' onClick={handleShow}><MdInsertComment /></button>
                                                    
                                                    <span>1210</span>
                                                </li> */}
                                                <li>
                                                    <button className='reelBtn' id='shareBtn' title='Share'><FaShareFromSquare /></button>
                                                   
                                                    <span class="text-center">Share</span>
                                                </li>
                                                {/* <li>
                                                    <button className='reelBtn' id='moreBtn' title='More'><IoMdMore /></button>
                                                    
                                                </li> */}

                                            </ul>
                                        </div>
                                        <Modal className='modal-lg commentsArea' show={showCmnt} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className='commentWrap'>
                                                    <div className='CommentTitle'>
                                                        <div><b>Comments</b><span>(62)</span></div>
                                                        <div className='cmntFilter' onClick={toggleDropdown} ref={filterRef}><LuFilter />
                                                            {isDropdownVisible && (
                                                                <ul className='filterDropdown'>
                                                                    <li onClick={dd}>Top Comments</li>
                                                                    <li onClick={dd}>Newest First</li>
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className='cmntBody'>
                                                        <div className='cmntmsg'>
                                                            <div className='cmntmsgHead'>
                                                                <div className='cmntUser'>
                                                                    <img width="100" height="100" src="https://img.icons8.com/color/100/user-male-circle--v1.png" alt="user-male-circle--v1" />
                                                                </div>
                                                                <div className='cmntUserInfo'>
                                                                    <b>@RedFlame64</b> <span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </SwiperSlide>
                                ))}

                            </Swiper>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
