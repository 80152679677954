import React from 'react';
import { useState } from 'react';
import catbg from '../../images/catbg.jpg';
import '../categoriepage/categories.css'
import Breadcumb from '../../components/breadcumb/Breadcumb';
import { Col, Row } from 'react-bootstrap';


import Videos from '../../components/common/Reellist';
import Reels from '../../components/common/Reellist';
import Trendingdata from '../../data/trending.json';
import Trendingdatareel from '../../data/trandingreel.json';




import { FaList } from "react-icons/fa";
import { IoGrid } from "react-icons/io5";

import {useParams,useSearchParams} from 'react-router-dom';

export default function Resultspage() {

  //const {search_query} = useSearchParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const search_query = searchParams.get('search_query')

  const [view, setView] = useState('grid');

  const handleListViewClick = () => {
    setView('list');
  };

  const handleGridViewClick = () => {
    setView('grid');
  };


  return (
    <>
        <section className='catBanner' style={{backgroundImage: `url(${catbg})`}}>
          <div className='container-fluid'>
            <Row>
              <Col sm={12}><Breadcumb pageTitle={"Did you mean: "+search_query} /></Col>
            </Row>
          </div>
        </section>

        <section className='catListing'>
          <div className='container-fluid'>
            <Row>
              <Col sm={12}>
                <div className='catFilter'>
                  <span>Filter by:</span>
                  <div className={`filterBtn ${view === 'list' ? 'active' : ''}`} id='listBTn' onClick={handleListViewClick}><FaList/>Videos</div>
                  <div className={`filterBtn ${view === 'grid' ? 'active' : ''}`} id='gridBtn' onClick={handleGridViewClick}><IoGrid/>Reels</div>
                </div>
              </Col>
            </Row>
            {view === 'grid' && (
              <div className='gridView'>
                <Row>
                    {
                      Trendingdatareel.map((reels)=>{
                        return(
                          <Reels Img={reels.Img} ImgAlt={reels.ImgAlt} Title={reels.Title} shortDetail={reels.shortDetail} userImg={reels.userImg} userImgTitle={reels.userImgTitle} Channel={reels.Channel} videoLink={reels.videoLink} classValue="col-sm-6 col-lg-2 col-xl-2" />
                        )
                      })
                    }
                </Row>
                <Row>
                  <Col sm={12} className='text-center'>
                    <button className="btn primary-btn" title="view all">Show More</button>
                  </Col>
                </Row>
            </div>
            
            )}

            {view === 'list' && (
              <div className='listView'>
                <Row>
                    {
                      Trendingdata.map((videos)=>{
                      return(
                        <Videos Img={videos.Img} ImgAlt={videos.ImgAlt} Title={videos.Title} shortDetail={videos.shortDetail} userImg={videos.userImg} userImgTitle={videos.userImgTitle} Channel={videos.Channel} videoLink={videos.videoLink} classValue="col-sm-6 col-lg-3 col-xl-3" />
                      )
                      })
                    }
                </Row>
                <Row>
                  <Col sm={12} className='text-center'>
                    <button className="btn primary-btn" title="view all">Show More</button>
                  </Col>
                </Row>
              </div>
            )}

          </div>
        </section>
    </>
  )
}
