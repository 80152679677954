import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';

import { TiTick } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";



export default function MySubscribe() {

    const [activeButton, setActiveButton] = useState(0); // Default to the first button
    const [formData, setFormdata] = useState({
        price: "195",
        month: "90 Days"
    })
  
    const handlePremiumClick = (index) => {
        setActiveButton(index);
    };

    const handleChange=(e)=>{
        const value = e.target.value;
        const month = e.target.getAttribute('data-month')

        setFormdata({
            ...formData,
            price : value,
            month : month,
        })
    }
  return (
    <>
          <h1 className="subscribe_heading">
              Subscribe now and start streaming
          </h1>
          <div className="subscribe_check_box">
                <div className={`premium ${activeButton === 0 ? 'activeradio' : ''}`} onClick={() => handlePremiumClick(0)}>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" data-month="90 Days" onChange={handleChange} id="flexRadioDefault3" value="195" checked={formData.price=="195"}/>
                        <label className="form-check-label" for="flexRadioDefault3">
                            <div className="premium_hd">
                              Subscribe Package
                              </div>
                              <div className="price_month">
                                <span className="pAmount">₹195</span>
                                <small>90 Days</small>
                              </div>
                        </label>
                      </div>
                </div>
            </div>

          <Table striped bordered hover id="subscribe_table" className={`${activeButton === 0 ? 'addnew' : ''}`}>
                <tr>
                    <th></th>
                    <th>Availability</th>
                </tr>
                <tr>
                    <td>
                        Movies
                        <br/>
                        <span className="specials">Access all Movies</span>
                    </td>
                    <td><TiTick/></td>
                </tr>
                <tr>
                    <td>Watch Social Reels
                        <br/>
                        <span className="specials">Access all Social Reels</span>
                    </td>
                    <td><TiTick/></td>
                </tr>
                <tr>
                    <td>Make Social Reels
                        <br/>
                        <span className="specials">Participate in Social Reels Sector, Make and Upload Video facility</span>
                    </td>
                    <td><TiTick/></td>
                </tr>
          </Table>

          <div className="package_btn">
                <div className="amountSelected">
                    <div className="amount">₹{formData.price}</div>
                    <small className="months">{formData.month}</small>
                </div>
                <button className="btn primary-btn" title="Play Now">Continue <IoIosArrowForward /></button>
            </div>
    </>
  )
}
