import React from 'react'
import { Row, Col } from 'react-bootstrap'
import NotificationSwitch from './NotificationSwitch'
import { Link } from 'react-router-dom'

export default function Privacy() {
  return (
    <>
        <Row>
            <Col sm={12}>
                <div class="contentFilterwrapper"><span>Manage what you share on YouTube</span></div>
                <p className='signPara'>Choose who can see your subscriptions</p>
            </Col>
        </Row>
        <Row className='align-items-center'>
            <Col sm={12} md={5}>
                <div className='subsWrapper'>
                    <span>Subscription</span>
                <NotificationSwitch switchTitle={"Keep all my subscriptions private"} switchPara={"Your subscriptions won't be visible to others, unless you use features that make them public. Learn more about what could make your subscriptions visible or manage your subscriptions here."}/>
                </div>
            </Col>
            <Col sm={12} md={7}>
                <div className='adsWrap'>
                    <b>Ads on SupportIndia</b>
                    <p>You may see ads on YouTube based on general factors, like the topic of a video. The ads you see may also depend on your choices on <Link to={""}>My Ad Center</Link>. To learn more about how ads work for family accounts with kids, visit the <Link to={""}>Google for Families Help Center.</Link></p>
                </div>
            </Col>
        </Row>
    </>
  )
}
