import React from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";
import ShortUniqueId from 'short-unique-id'
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import { HiPlus } from "react-icons/hi";
import { SlControlPlay } from "react-icons/sl";
import { IoShareSocialSharp } from "react-icons/io5";
import { FaInfo } from "react-icons/fa6";
export default function Vodlist(props) {
    const uid = new ShortUniqueId({ length: 5 });
  return (
    <>
          
          <div className="mostwatchedItem" key={uid.rnd()}>

<Link to={props.videoLink} className="mostWatchedImg" title={props.Title}>
                    
                    <LazyLoadImage  src={props.Img}
              placeholderSrc={props.Img}
              alt={props.ImgAlt}
              effect="blur"
              className="img-fluid"
              wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
              }}

          />


                </Link>
<div className="mwOverley">
        <ul className="optionbar">
            <li><div className="playbtn" title='play'><SlControlPlay/></div></li>
            <li><div className="optionBtn mwOaddbtn"><HiPlus /></div></li>
            <li><div className="optionBtn"><IoShareSocialSharp/></div></li>
            <li><div className="optionBtn"><FaInfo/></div></li>
        </ul>

        <div className="mwInfo">
        <Link to={props.videoLink}   title={props.Title}>{props.Title}</Link>
            <b>Season 1 </b>
            <p className="">{props.shortDetail}</p>

            <div className="details">
                <span className="detailtypeH">Genre :</span> <span className="detailType">Drama</span>
            </div>
            <ul className="timing">
                <li>00 H 35 Min</li>
                <li>2021</li>
                <li><div className="ageres">U</div></li>
            </ul>
        </div>
</div>
</div>

    </>
  )
}



