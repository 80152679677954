import React from 'react'
import { Row, Col } from 'react-bootstrap'
import SectionTitle from '../../../includes/sectionTitle/SectionTitle'
import NotificationSwitch from './NotificationSwitch';

export default function Notification() {
  return (
    <>
        <Row>
            <Col sm={12}>
            <div class="contentFilterwrapper"><span>Choose when and how to be notified</span></div>
            <p className='signPara'>Select push and email notifications you'd like to receive</p>
            </Col>

            <Col sm={12}>
                <div className='generalWrapper'>
                <SectionTitle stitle="General"/>
                    <Row>
                        <Col sm={6}>
                            <NotificationSwitch switchTitle={"Subscriptions"} switchPara={"Notify me about activity from the channels I'm subscribed to"}/>
                        </Col>
                        <Col sm={6}>
                            <NotificationSwitch switchTitle={"Recommended videos"} switchPara={"Notify me of videos I might like based on what I watch"}/>
                        </Col>
                        <Col sm={6}>
                            <NotificationSwitch switchTitle={"Activity on my channel"} switchPara={"Notify me about comments and other activity on my channel or videos"}/>
                        </Col>
                        <Col sm={6}>
                            <NotificationSwitch switchTitle={"Replies to my comments"} switchPara={"Notify me about activity on my comments and posts on other channels"}/>
                        </Col>
                        <Col sm={6}>
                            <NotificationSwitch switchTitle={"Mentions"} switchPara={"Notify me when others mention my channel"}/>
                        </Col>
                        <Col sm={6}>
                            <NotificationSwitch switchTitle={"Shared content"} switchPara={"Notify me when others share my content on their channels"}/>
                        </Col>
                    </Row>
                </div>
            </Col>

            {/* <Col sm={12}>
                <div className='generalWrapper'>
                <SectionTitle stitle="Email notifications"/>
                    <Row>
                    <Col sm={12} md={6}>
                        <NotificationSwitch switchTitle={"Send me emails about family and product updates for YouTube or YouTube Kids"} switchPara={"By turning on this setting, you're opting in to emails with recommended content, tips, and product updates for families"}/>
                    </Col>
                    <Col sm={12} md={6}>
                        <NotificationSwitch switchTitle={"Send me emails about my YouTube activity and updates I requested"} switchPara={"If this setting is turned off, YouTube may still send you messages regarding your account, required service announcements, legal notifications, and privacy matters"}/>
                    </Col>
                    <Col sm={12} md={6}>
                        <NotificationSwitch switchTitle={"General product updates"} switchPara={"Announcements and recommendations"}/>
                    </Col>
                    <Col sm={12} md={6}>
                        <NotificationSwitch switchTitle={"YouTube Premium updates"} switchPara={"Announcements, updates, and recommendations from YouTube Premium and YouTube Music Premium"}/>
                    </Col>
                    <Col sm={12} md={6}>
                        <NotificationSwitch switchTitle={"Creator updates and announcements"} switchPara={"Product announcements, creator events, and personalized tips to grow your YouTube channel"}/>
                    </Col>
                    </Row>
                </div>
            </Col> */}
        </Row>
    </>
  )
}
