import React, { useState, useEffect, useRef } from 'react'

import { Link } from 'react-router-dom'

import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import singleVideoData from '../../data/single-video.json';



import { Col, Container, Row } from 'react-bootstrap';
import '../singlepage/singlepage.css';

import { useParams } from 'react-router-dom';
// import herobanner from '../../images/herobanner/banner1.jpg';
import advertisement1 from '../../images/advertisement1.png';


import { IoIosAdd } from "react-icons/io";
import { SlControlPlay } from "react-icons/sl";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa6";
import { LuBellRing } from "react-icons/lu";
import { BiCommentDetail } from "react-icons/bi";
import Addsection from '../home/addsSection/Addsection';


import Reellist from '../../components/common/Reellist';
import Trendingdata from '../../data/trending.json';
import Trendingreeldata from '../../data/trandingreel.json';


import SectionTitle from '../../includes/sectionTitle/SectionTitle';

import videofile from '../../videos/Rajiv-Gandhi-International-cricket-stadium.mp4'

//videojs config
import videojs from "video.js";
import "video.js/dist/video-js.css";
import videoJsContribQualityLevels from "videojs-contrib-quality-levels"
import videojsHlsQualitySelector from "videojs-hls-quality-selector"
videojs.registerPlugin("qualityLevel", videoJsContribQualityLevels)
videojs.registerPlugin("hlsQualitySelector", videojsHlsQualitySelector)
//videojs config

const videourl= [
    {src: "https://content.jwplatform.com/manifests/vM7nH0Kl.m3u8", type: "application/x-mpegURL"},
   
    {src: "http://qthttp.apple.com.edgesuite.net/1010qwoeiuryfg/sl.m3u8", type: "application/x-mpegURL"},

    {src: "https://s3.ap-south-1.amazonaws.com/videos.supportindiaapp.com/video/nyhTTM-HlUM/playlist.m3u8", type: "application/x-mpegURL"},
    
    

    {src: videofile, type: "video/mp4"},
];





  
  









//videojs config
const Video = (props) => {
    const videoNode = useRef(null);
    const [player, setPlayer] = useState(null);
    useEffect(() => {
        if (videoNode.current) {
            const _player = videojs(videoNode.current, props);
            setPlayer(_player);
            return () => {
                if (player !== null) {
                    player.dispose();
                }
            };
        }
    }, []);

    return (
        <div data-vjs-player>
            <video ref={videoNode} className="video-js"></video>
        </div>
    );
};
//videojs config


export default function Singlepage() {
    
    
    const catName = ['sports', 'education', 'kids','entertainment','news','reels'];

    const randomElement = catName[Math.floor(Math.random() * catName.length)];  
  
    const [trendingdata, setTrendingdata] = useState([]);
  
    
    
    const importData = async () => {
      import(`../../data/video-${randomElement}.json`)
        .then((res) => setTrendingdata(res.default))
        .catch(_ => null);
    };
    
    
    useEffect( () => {
      importData();
      //importDataReel();
    }, [importData]);



   const [showMore, setShowMore] = useState(false);
   const [videoCategory, setVideoCategory] = useState(false);
  // const {videoDesc} = singleVideoData[0];
  // const {videolessDesc} = videoDesc.substring(0,100);

   //console.log(videoDesc);
    
    const { videoId } = useParams();
    const [isVideoVisible, setVideoVisible] = useState(false);


    const handleNewClick = () => {
        setVideoVisible(true);
        videoRef.current.play();
        document.querySelector(".homepage_singlebanner").classList.add("mobileScren");
    };

    const handleVideoClick = () => {
        const video = videoRef.current;
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
    };

    //videojs config

    const options = {
        fill: true,
        fluid: true,
        preload: "auto",
        enableSmoothSeeking: true,
        responsive: true,
        aspectRatio: "9:16",
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        controlBar: {
            skipButtons: {
                forward: 5
            },
            skipButtons: {
                backward: 10
            }
        },
        html5: {
            hls: {
                enableLowInitialPlaylist: true,
                smoothQualityChange: true,
                overrideNative: true,
            },
        },
        plugins: {
            qualityLevel: {},
            hlsQualitySelector: { displayCurrentQuality: true },
        },
    }
    const [isPlaying, setIsPlaying] = useState(null);
    const videoRef = useRef(null);

    //videojs config




   





    return (
        <>
            <section className='single_page_sec'>
                <div className='container-fluid'>
                    <Row>
                        <Col sm={12} md={9}>
                        {
                    singleVideoData.map((viddetails, index) => {
                        if (viddetails.videoId==videoId) {
                           
                          return(
                            <div className='singelbanner homepage_singlebanner'>
                                <div className="mobiledetailspageImg2">


                                
                           
                      


                                    <Video  {...options}
                                        id={videoId}
                                        poster={viddetails.Img}
                                        loop={true}
                                        key={videoId}
                                        fill={true}
                                        fluid={true}
                                        autoplay={false}
                                        controls={true}
                                        displayCurrentQuality={true}
                                        preload={"metadata"}
                                        disablePictureInPicture={true}
                                        sources={[
                                            {src: viddetails.videoLink, type: "application/x-mpegURL"},
                                        ]} />


                                </div>
                                <div className="singlebannerWrapper">
                                    <div className="detailspageImg1" >
                                        <div className="detailspageImg_Container1 p-sm-5">
                                            <h1>{viddetails.Title}</h1>


                                            <div className="row align-items-center">
                                                <div className="col-md-4 pt-2"> <div className="reeluserInfo mb-0">
                                                    <Link className="reeluserimg" to={viddetails.Channel} title={viddetails.userImgTitle}>

                                                        <LazyLoadImage title="" className="img-fluid" src={viddetails.userImg}
                                                            placeholderSrc={viddetails.userImg}
                                                            alt={viddetails.userImgTitle}
                                                            effect="blur"
                                                            wrapperProps={{
                                                                // If you need to, you can tweak the effect transition using the wrapper style.
                                                                style: { transitionDelay: "1s" },
                                                            }}

                                                        />

                                                    </Link>
                                                   <div><Link to={viddetails.Channel} className="reelusername" title={viddetails.userImgTitle}>{viddetails.userImgTitle}</Link>
                                                    <span className="text-white">1 subscribers</span></div> 
                                                </div></div>
                                                <div className="col-md-8 d-flex justify-content-end btnclass gap-2 pt-2">


                                                    <button className="btn primary-btn bannerbtn2 actn2" title="Share" data-toggle="tooltip" data-placement="top"><span><FaRegShareFromSquare /></span></button>

                                                    <button className="btn primary-btn bannerbtn2 actn2" title="Like" data-toggle="tooltip" data-placement="top"><span><FaRegHeart /></span> {viddetails.videoId}</button>

                                                    <button className="btn primary-btn bannerbtn2" title="Subscribe" data-toggle="tooltip"><span><LuBellRing /></span> Subscribe</button>

                                                    <button className="btn primary-btn bannerbtn2" title="Comments" data-bs-toggle="modal" data-bs-target="#exampleModal2"><span><BiCommentDetail /></span> Comments</button>
                                                </div>
                                            </div>
                                            <br />
                                            <ul className="featureListing">
                                                <li>
                                                    <div className="ageRating">
                                                    100 views</div>
                                                </li>
                                                <li>2024-09-13</li>
                                               
                                            </ul>
                                            <p className="descriptionPara1">{viddetails.shortDetail}</p>
                                            


                                        </div>
                                    </div>
                                </div>

                            </div>
                          )
                              }
                            })
                        }
                        </Col>
                        <Col sm={12} md={3}>
                            <div className='sideads'>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="adsWrapper adsTop">
                                            <span className="adTag">Advertisement</span>
                                            <div className="adsImg">
                                                <img src={advertisement1} alt="ad1" loading="lazy" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <SectionTitle stitle="Recommended For You" />
                                    </div>


                                  



                                     {
                                         trendingdata.map((gridvalue) => {
                                             return (
                                                 <Reellist Img={gridvalue.Img} ImgAlt={gridvalue.ImgAlt} Title={gridvalue.Title} shortDetail={gridvalue.shortDetail} userImg={gridvalue.userImg} userImgTitle={gridvalue.userImgTitle} Channel={gridvalue.Channel} videoId={"/watch/"+gridvalue.videoId} classValue="col-12 pt-3 pb-3" />
                                             )
                                         })
                                     }


                                

                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="adsWrapper mt-5 mb-5">
                                            <span className="adTag">Advertisement</span>
                                            <div className="adsImg">
                                                <img src={advertisement1} alt="ad1" loading="lazy" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                

                            </div>

                        </Col>
                    </Row>
                   
                    <br /><br />
                </div>
            </section>
        </>
    )
}
