import React from 'react'

export default function SectionTitle(props) {
  return (
    <>
        
        <h2 className="sectionTitle">
                        <span>{props.stitle}</span>
        </h2>
    </>
  )
}
