import React from 'react';
import { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import googleimg from  '../../images/google.png'
import { Col, Form, Row } from 'react-bootstrap';

export default function Login() {

  // const [activeTab, setActiveTab] = useState('home');

  // useEffect(() => {
  //   const handleHomeTabClick = () => {
  //     setActiveTab('home');
  //   };

  //   const handleProfileTabClick = () => {
  //     setActiveTab('profile');
  //   };

  //   const homeTab = document.getElementById('tab:r1:0');
  //   const profileTab = document.getElementById('tab:r1:1');

  //   homeTab.addEventListener('click', handleHomeTabClick);
  //   profileTab.addEventListener('click', handleProfileTabClick);

  //   // Cleanup event listeners on component unmount
  //   return () => {
  //     homeTab.removeEventListener('click', handleHomeTabClick);
  //     profileTab.removeEventListener('click', handleProfileTabClick);
  //   };
  // }, []);

    const [showOtpForm, setShowOtpForm] = useState(false);
  
    const handleGetOtpClick = () => {
      setShowOtpForm(true);
    };

  return (
    <>
        <div className="logtab">
        <Tabs id='mytab' className="">
            <TabList>
            <Tab>Log In</Tab>
            <Tab>Sign Up</Tab>
            </TabList>

            <TabPanel>
                <div className='logFilter'>
                    <Tabs>
                        <Row>
                            <Col sm={12} lg={4}>
                                <div className='logFilterMenu'>
                                    <TabList>
                                        <Tab>Log in with OTP</Tab>
                                        <Tab>Log in with Password</Tab>
                                    </TabList>
                                </div>
                            </Col>
                            <Col sm={12} lg={8}>
                                <div className='logFilterBody'>
                                    <TabPanel>
                                    <div className="signInForm" style={{ display: showOtpForm ? 'none' : 'block' }}>
                                        <div className="logHeader">
                                                <b>Login to SupportIndia</b>
                                                <p>Login with OTP to continue enjoying uninterrupted video and personalised experience.</p>
                                        </div>

                                        
                                        <Form>
                                            <div className="form-group" >
                                                <label for="">Mobile Number</label>
                                                <input type="text" placeholder="Enter Mobile Number" className="form-control"/>
                                            </div>
                                            <button className="btn primary-btn" title="Play Now" type='button' onClick={handleGetOtpClick}>Get OTP</button>
                                        </Form>

                                            
                                        
                                        </div>
                                        <Form className="otpForm" style={{ display: showOtpForm ? 'block' : 'none' }}>
                                                <div className="logHeader">
                                                    <b>Sumit OTP</b>
                                                    <p>To create account Submit OTP which was sent to your submitted Phone No.</p>
                                                </div>
                                                <div className="otpFields">
                                                    <input type="text" maxlength="1" className="form-control"/>
                                                    <input type="text" maxlength="1" className="form-control"/>
                                                    <input type="text" maxlength="1" className="form-control"/>
                                                    <input type="text" maxlength="1" className="form-control"/>
                                                </div>
                                                <p className="">OTP will expired in <span id="timer" className="text-danger">2m 59s</span></p>
                                        </Form>
                                    </TabPanel>
                                    <TabPanel>
                                    <div className="logHeader">
                                            <b>Login to SupportIndia</b>
                                            <p>Login with Password to continue enjoying uninterrupted video and personalised experience.</p>
                                    </div>
                                              <Form>
                                                  <div className="form-group" >
                                                      <label for="">Email Id</label>
                                                      <input type="text" placeholder="Enter Valid Email ID" className="form-control" />
                                                  </div>
                                                  <div className="form-group" >
                                                      <label for="">Password</label>
                                                      <input type="text" placeholder="Enter Password" className="form-control" />
                                                  </div>
                                                  <button className="btn primary-btn" title="Play Now" type='button' >Submit</button>
                                              </Form>
                                    </TabPanel>
                                </div>
                            </Col>
                        </Row>
                    </Tabs>
                </div>
                

            </TabPanel>
            <TabPanel>
                    <div className="signUpForm" style={{ display: showOtpForm ? 'none' : 'block' }}>
                        <div className="logHeader">
                          <b>Create a new account</b>
                          <p>Create an account to continue enjoying uninterrupted video and personalised experience. <strong style={{color: "green"}}>All (*) marked fields are mandatory</strong></p>
                        </div>

                        <Form>
                            <div className="form-group">
                                <label for="">Name <strong style={{color: "green", fontSize: "16px"}}>*</strong></label>
                                <input type="text" placeholder="Your Full Name" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label for="">Emil ID</label>
                                <input type="text" placeholder="Your Email Address" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label for="">Mobile Number <strong style={{color: "green", fontSize: "16px"}}>*</strong></label>
                                <input type="text" placeholder="Enter Mobile Number" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label for="">City</label>
                                <input type="text" placeholder="Type your City" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label for="">Ref Code</label>
                                <input type="text" placeholder="Enter Ref Code" className="form-control"/>
                            </div>
                            <div className="form-group">
                                <label for="">Danveer Code</label>
                                <input type="text" placeholder="Enter Danveer Code" className="form-control"/>
                            </div>
                            <button type="button" className="btn primary-btn" title="Play Now" id="getOtp" onClick={handleGetOtpClick}>Get OTP</button>
        
                        </Form>
                    </div>



                   
            </TabPanel>
        </Tabs>

        <div className="alternate"><span>or Continue with</span></div>

<button className="googleBtn" type='button'><img src={googleimg} alt="googleIcon"/>Log In with Google</button>
        </div>
    </>
  )
}
