import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Chart from "react-apexcharts";
import ChannelInfo from '../ChannelInfo';
import { TbClockPlus } from "react-icons/tb";
import { FaRegEye } from "react-icons/fa";


export default function Analytics() {

    const [state, setstate] = useState(
        {
            options: {
              colors: ['#ff5722', '#66DA26'],
              chart: {
                id: "SupportIndiauser"
              },
              xaxis: {
                categories: ["Aug-2023", "Sep-2023", "Oct-2023", "Nov-2023", "Dec-2023", "Jan-2024", "Feb-2024", "Mar-2024", "Apr-2024", "May-2024", "Jun-2024", "Jul-2024"]
              }
            },
            series: [
              {
                name: "series-1",
                data: [100, 560, 900, 1600, 1200, 2000, 1000, 2500, 900, 1600, 3600, 2000]
              }
            ]
          }
    )
  return (
    <>
          <Row>
              <Col sm={7} lg={8} xl={9}>
                  <div className='contentFilterwrapper'>
                      <span>Channel Analytics</span>
                      <span>in Last 1 Year</span>
                  </div>

                  <Row>
                    <Col sm={12}>
                        <div className='chartWrapper'>
                        <Chart
                            options={state.options}
                            series={state.series}
                            type="area"
                            width="100%"
                            />
                        </div>
                    </Col>
                  </Row>
              </Col>
              <Col sm={5} lg={4} xl={3}>
                        <div className='channelinfoBar'>
                            <ChannelInfo/>
                            <div className='channelProfile'>
                                <div className='profileqInfo'>
                                    <span><FaRegEye /></span>
                                    <div className='profileqData'>
                                        <small>Total Views</small>
                                        <b>1.5 K</b>
                                    </div>
                                </div>
                                <div className='profileqInfo'>
                                    <span><TbClockPlus /></span>
                                    <div className='profileqData'>
                                        <small>Total Watchtime</small>
                                        <b>1000 Hrs</b>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
              </Col>
          </Row>
    </>
  )
}
