import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import '../singlepage/singlepagevod.css';
import { useState, useRef } from 'react';
import {useParams} from 'react-router-dom';
// import herobanner from '../../images/herobanner/banner1.jpg';
import advertisement1 from '../../images/advertisement1.png';

import {Link} from 'react-router-dom'
import { IoIosAdd } from "react-icons/io";
import { SlControlPlay } from "react-icons/sl";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa6";
import { LuBellRing } from "react-icons/lu";
import { BiCommentDetail } from "react-icons/bi";
import Addsection from '../home/addsSection/Addsection';


import Reellist from '../../components/common/Reellist';
import Trendingdata from '../../data/trending.json';
import Trendingreeldata from '../../data/trandingreel.json';


import SectionTitle from '../../includes/sectionTitle/SectionTitle';




export default function Singlepagevod() {
    const {videoId} = useParams();
    const [isVideoVisible, setVideoVisible] = useState(false);
    const videoRef = useRef(null);

    const handleNewClick = () => {
        setVideoVisible(true);
        videoRef.current.play();
        document.querySelector(".homepage_singlebanner").classList.add("mobileScren");
    };

    const handleVideoClick = () => {
        const video = videoRef.current;
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
    };


    return (
        <>
            <section className='single_page_sec'>
                <div className='container-fluid'>
                    <Row>
                        <Col sm={12} md={12}>
                            <div className='singelbanner homepage_singlebanner'>
                                <div className="mobiledetailspageImg" style={{ backgroundImage: `url(https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg)` }}></div>
                                <div className="singlebannerWrapper">
                                    <div className="detailspageImg" style={{ backgroundImage: `url(https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg)` }}>
                                        <div className="detailspageImg_Container">
                                            <h1>The Eken: Ruddhaswas Rajasthan</h1>
                                            <ul className="featureListing">
                                                <li>
                                                    <div className="ageRating">U/A 7+</div>
                                                </li>
                                                <li>119 mins</li>
                                                <li>Detective Comedy</li>
                                            </ul>
                                            <p className="descriptionPara">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto, nemo quis minima omnis officia similique voluptas nesciunt, pariatur repellat doloremque nostrum ducimus provident suscipit odio impedit atque totam!</p>

                                            <div className="videoaddInfo">
                                                <span className="videoCredit">Director :</span>
                                                <div className="videoCredittext"><Link to=""><span>Joydeep Mukherjee</span></Link></div>
                                            </div>
                                            <div className="videoaddInfo">
                                                <span className="videoCredit">Starring :</span>
                                                <div className="videoCredittext"><span>Anirban Chakrabarti, </span><span>Suhotra Mukhopadhyay, </span><span>Somak Ghosh, </span><span>Sandipta Sen, </span><span>Rajatava Dutta, </span><span>Rajesh Sharma </span></div>
                                            </div>

                                            <div className="bannerBtns">
                                                <button className="btn primary-btn new" title="Play Now" onClick={handleNewClick}><span><SlControlPlay /></span>Subscribe Now to Watch</button>

                                                <button className="btn primary-btn bannerbtn2 addbtn" title="Add Watchlist" data-toggle="tooltip" data-placement="top"><span><IoIosAdd /></span></button>

                                                <button className="btn primary-btn bannerbtn2 actn2" title="Share" data-toggle="tooltip" data-placement="top"><span><FaRegShareFromSquare /></span></button>

                                                <button className="btn primary-btn bannerbtn2 actn2" title="Like" data-toggle="tooltip" data-placement="top"><span><FaRegHeart /></span></button>

                                                <button className="btn primary-btn bannerbtn2" title="Subscribe" data-toggle="tooltip"><span><LuBellRing /></span>Subscribe</button>

                                                <button className="btn primary-btn bannerbtn2" title="Comments" data-bs-toggle="modal" data-bs-target="#exampleModal2"><span><BiCommentDetail /></span>Comments</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="video-wrapper" style={{ zIndex: isVideoVisible ? 1 : -1, opacity: isVideoVisible ? 1 : 0 }} >
                                    <video controls className="video ottvideo" width='100%' ref={videoRef} onClick={handleVideoClick}>
                                        <source src="https://riverisland.scene7.com/is/content/RiverIsland/c20171205_Original_Penguin_AW17_Video" type="video/mp4" />
                                        <source src="https://riverisland.scene7.com/is/content/RiverIsland/c20171205_Original_Penguin_AW17_Video_OGG" />
                                        <img src=" fall-back image" alt="" />
                                    </video>
                                </div>
                            </div>
                        </Col>
                        {/* <Col sm={12} md={3}>
                            <div className='sideads'>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="adsWrapper adsTop">
                                            <span className="adTag">Advertisement</span>
                                            <div className="adsImg">
                                                <img src={advertisement1} alt="ad1" loading="lazy" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <SectionTitle stitle="Recommended For You" />
                                    </div>
                                    {
                                        Trendingdata.map((gridvalue) => {
                                            return (
                                                <Reellist Img={gridvalue.Img} ImgAlt={gridvalue.ImgAlt} Title={gridvalue.Title} shortDetail={gridvalue.shortDetail} userImg={gridvalue.userImg} userImgTitle={gridvalue.userImgTitle} Channel={gridvalue.Channel} videoLink={gridvalue.videoLink} classValue="col-12 pt-3 pb-3" />
                                            )
                                        })
                                    }

                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="adsWrapper mt-5 mb-5">
                                            <span className="adTag">Advertisement</span>
                                            <div className="adsImg">
                                                <img src={advertisement1} alt="ad1" loading="lazy" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    {
                                        Trendingreeldata.map((gridvalue) => {
                                            return (
                                                <Reellist Img={gridvalue.Img} ImgAlt={gridvalue.ImgAlt} Title={gridvalue.Title} shortDetail={gridvalue.shortDetail} userImg={gridvalue.userImg} userImgTitle={gridvalue.userImgTitle} Channel={gridvalue.Channel} videoLink={gridvalue.videoLink} classValue="col-6 pt-3 pb-3" />
                                            )
                                        })
                                    }

                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="adsWrapper mt-5 mb-5">
                                            <span className="adTag">Advertisement</span>
                                            <div className="adsImg">
                                                <img src={advertisement1} alt="ad1" loading="lazy" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </Col> */}
                    </Row>
                    <Row>
                        <div className="col-sm-12">
                            <SectionTitle stitle="Related Videos" />
                        </div>
                        {
                            Trendingdata.map((gridvalue) => {
                                return (
                                    <Reellist Img={gridvalue.Img} ImgAlt={gridvalue.ImgAlt} Title={gridvalue.Title} shortDetail={gridvalue.shortDetail} userImg={gridvalue.userImg} userImgTitle={gridvalue.userImgTitle} Channel={gridvalue.Channel} videoLink={gridvalue.videoLink} classValue="col-sm-4 col-md-3 col-lg-3 col-12 pt-3 pb-3" />
                                )
                            })
                        }
                    </Row>
                    <Row>
                        <div className="col-sm-12 clearfix">
                            <Addsection />
                        </div>
                    </Row>
                    <Row>
                        <div className="col-sm-12">
                            <SectionTitle stitle="You May Like" />
                        </div>
                        {
                            Trendingreeldata.map((gridvalue) => {
                                return (
                                    <Reellist Img={gridvalue.Img} ImgAlt={gridvalue.ImgAlt} Title={gridvalue.Title} shortDetail={gridvalue.shortDetail} userImg={gridvalue.userImg} userImgTitle={gridvalue.userImgTitle} Channel={gridvalue.Channel} videoLink={gridvalue.videoLink} classValue="col-sm-4 col-md-3 col-lg-2 col-12 pt-3 pb-3" />
                                )
                            })
                        }

                    </Row>
                    <br /><br />
                </div>
            </section>
        </>
    )
}
