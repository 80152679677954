import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineLike } from "react-icons/ai";
import { BiDislike } from "react-icons/bi";
import { FcLike } from "react-icons/fc";
import { RiDeleteBinFill } from "react-icons/ri";

export default function CommentCard({ checkId, videoThumb, videoTitle, commentData, handleCheckboxChange, videoInfo, viewersComment }) {
  return (
    <tr className={`commentTr ${commentData.checked ? 'selected' : ''}`}>
      <td>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id={checkId}
            checked={commentData.checked}
            onChange={() => handleCheckboxChange(checkId)}
          />
          <label className="form-check-label" htmlFor={checkId}></label>
        </div>
      </td>
      <td>
        <div className='tableVthumb'>
          <div className='vthumbimg'>
            <img src={videoThumb} loading='lazy' className='img-fluid' />
          </div>
          <div className='vthumbinfo'>
            <b>{videoTitle}</b>
            <p>{videoInfo}</p>
          </div>
        </div>
      </td>
      <td>
        <div className='replyArea'>
          <div className='replyItem'>
            <div className='replydp'><Link to={''} className='replyUser'>R</Link></div>
            <div className='replyInfo'>
              <ul className='replyHeader'>
                <li><span>2 days ago</span></li>
                <li><Link className='replymail'>@saptarshiroy3704</Link></li>
              </ul>
              <p className='replyText'>{viewersComment}</p>
              <div className='replyOptions'>
                <button className='btn primary-btn'>Reply</button>
                <button className='btn primary-btn'>1 Reply</button>
                <div className='likeBtn'><AiOutlineLike /> <span>1</span></div>
                <div className='likeBtn'><BiDislike /></div>
                <div className='likeBtn'><FcLike /></div>
              </div>
            </div>
          </div>
        </div>
        <div className='replyArea replyBack'>
          <div className='replyItem'>
            <div className='replydp'><Link to={''} className='replyUser'>R</Link></div>
            <div className='replyInfo'>
              <ul className='replyHeader'>
                <li><span>2 days ago</span></li>
                <li><Link className='replymail'>@saptarshiroy3704</Link></li>
              </ul>
              <p className='replyText'>the table will behave normally and not scroll horizontally.</p>
              <div className='replyOptions'>
                <button className='btn primary-btn'>Reply</button>
                <button className='btn primary-btn'>1 Reply</button>
                <div className='likeBtn'><AiOutlineLike /> <span>1</span></div>
                <div className='likeBtn'><BiDislike /></div>
                <div className='likeBtn'><FcLike /></div>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <button className='likeBtn delBtn'><RiDeleteBinFill /> Delete</button>
      </td>
    </tr>
  );
}
