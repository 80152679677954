import React from 'react'
import { Col, Row } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import {Link} from 'react-router-dom'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import '../userDashboard/dashboard.css';

import banner01 from '../../images/banner-1.png'
import banner02 from '../../images/banner-2.png'
import lock from '../../images/lock.png'

import { FiUserPlus } from "react-icons/fi";
import { IoIosLock } from "react-icons/io";
import { PiTelevisionBold } from "react-icons/pi";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import { FaEye } from "react-icons/fa";
import { TfiWorld } from "react-icons/tfi";
import { LuBellRing } from "react-icons/lu";
import { ImFilm } from "react-icons/im";
import { MdOutlinePreview } from "react-icons/md";
import { BsPatchExclamation } from "react-icons/bs";
import { GiIndiaGate } from "react-icons/gi";

import Table from 'react-bootstrap/Table';
import ChannelProfile from '../channelprofile/Channelprofile';
import { IoImagesOutline } from "react-icons/io5";
import { IoReloadOutline } from "react-icons/io5";
import { GoVideo } from "react-icons/go";
import { IoSettingsOutline } from "react-icons/io5";

import History from './History';
import Yourvideos from './yourvideos/Yourvideos';
import Settings from './setting/Settings';

export default function Dashboard() {
    const options = {
        loop:true,
        margin:0,
        // autoWidth:true,
        dots: false,
        navText: ['<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>','<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg>'],
        autoplay: true,
        autoplayTimeout: 7000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
            
  };
  return (
    <>
        <section className='userDashboard'>
            <div className='container-fluid'>
            <Tabs>
                <Row>
                    <Col sm={12} lg={3} xl={2}>
                        <div className='user_dashboard'>
                            <TabList>
                                <Tab><FiUserPlus/> User Details</Tab>
                                <Tab><IoIosLock/> Change password</Tab>
                                <Tab><PiTelevisionBold /> Channel details</Tab>
                                <Tab><IoImagesOutline /> Your Channel</Tab>
                                <Tab><IoReloadOutline /> History</Tab>
                                <Tab><GoVideo /> Your Video</Tab>
                                <Tab><IoSettingsOutline /> Settings</Tab>
                            </TabList>
                        </div>
                    </Col>
                    <Col sm={12} lg={9} xl={10}>
                        <TabPanel>
                            <div className='user_details_wrapper'>
                                <Row>
                                    <Col sm={6} md={4}>
                                    <div class="user_details_main">
                                            <div class="user_details">
                                                <div class="user_profile_details">
                                                    <div className='userPImg'><FaRegCircleUser /></div>
                                                    <h5>User Profile</h5>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur
                                                        cum a cumque quidem, natus voluptatibus incidunt numquam placeat
                                                        commodi, totam, corrupti sint esse doloribus aliquid!</p>
                                                </div>
                                            </div>
                                            <div class="user_details">
                                                <h5>User Details</h5>
                                                <Table className='user_details_table'>
                                                    <tbody>
                                                        <tr>
                                                            <td><FaRegUser /></td>
                                                            <td>
                                                                <b>User Name</b>
                                                                <p>AB</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><MdOutlineEmail /></td>
                                                            <td>
                                                                <b>Email</b>
                                                                <p>aa@aa.com</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><GoLocation /></td>
                                                            <td>
                                                                <b>City</b>
                                                                <p>Kollkata</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4}>
                                        <div class="user_details achievement_views">
                                            <h5>New achievement</h5>
                                            <div class="viewss">
                                                <div class="view_icon">
                                                    <FaEye />
                                                </div>
                                                <div class="views_details">
                                                    <b>7,0000 views</b>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                        Accusantium placeat ea veritatis vel</p>
                                                    <button class="btn primary-btn" title="Play Now">view
                                                        analytics</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="user_details">
                                            <h5>Top Videos</h5>
                                            <b class="video_details_heading">Last 24 hours Views</b>
                                            <ul class="top_videos_list">
                                                <li>Subscribe For Daily Khan Sir Shorts Videos..!!</li>
                                                <li>JJ and TomTom stage a heroic rescue with their favorite sea creature toys during bath time! Subscribe for new videos every week! </li>
                                                <li>Akani Simbine delivers incredible anchor leg to bring home #WorldRelays victory for South Africa in 4x100m with 38.71.</li>
                                              
                                            </ul>
                                            <button class="btn primary-btn" title="Play Now">view analytics</button>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4}>
                                    <div class="user_details_main">
                                            <div class="user_details">
                                                <h5>News</h5>
                                                <OwlCarousel className='user_profile_slider owl-theme' {...options}>
                                                <div class="item">
                                                        <img src={banner01} alt="" title="" loading="lazy"/>
                                                        <b>Your Monthly Roundup is here!</b>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed
                                                            facere sint officia odit libero aut, molestias perspiciatis
                                                            accusamus consequatur quia debitis nihil soluta numquam.
                                                            Cum!</p>
                                                        <button class="btn primary-btn" title="Play Now">click it
                                                            out</button>
                                                    </div>
                                                    <div class="item">
                                                        <img src={banner02} alt="" title="" loading="lazy"/>
                                                        <b>Your Monthly Roundup is here!</b>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed
                                                            facere sint officia odit libero aut, molestias perspiciatis
                                                            accusamus consequatur quia debitis nihil soluta numquam.
                                                            Cum!</p>
                                                        <button class="btn primary-btn" title="Play Now">click it
                                                            out</button>
                                                    </div>
                                                </OwlCarousel>
                                                
                                            </div>
                                            {/* <div class="user_details">
                                                <h5>
                                                    What's new in Studio
                                                </h5>
                                                <ul class="studio_list">
                                                    <li>
                                                        "Top community clips" available now
                                                    </li>
                                                    <li>Expansion of channel permissions</li>
                                                    <li>Upcoming changes to community guidlines warning</li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='user_details_wrapper'>
                                <Row>
                                    <Col sm={8} md={6}>
                                    <div class="user_details_main">
                                            <div class="user_details">
                                                <div class="password_img">
                                                    <img src={lock} alt="" title="" loading="lazy"/>
                                                </div>
                                                <div class="change_password_div">
                                                    <h5>Change Password</h5>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum
                                                        doloremque perferendis facilis quisquam beatae cumque temporibus
                                                        maiores corrupti dolor odit, eveniet ab distinctio quos itaque.
                                                    </p>

                                                    <form class="change_password_form">
                                                        <div class="form-group">
                                                            <label for="">Current password</label>
                                                            <input type="text" placeholder="Enter Current password"
                                                                class="form-control"/>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="">New password</label>
                                                            <input type="text" placeholder="Enter New password"
                                                                class="form-control"/>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="">Conform password</label>
                                                            <input type="text" placeholder="Enter Conform password"
                                                                class="form-control"/>
                                                        </div>
                                                        <button class="btn primary-btn" title="Play Now">Conform
                                                            password</button>

                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='user_details_wrapper'>
                                <Row>
                                    <Col sm={8} md={6}>
                                    <div class="user_details_main">
                                            <div class="user_details">
                                                <div class="change_password_div">
                                                    <h5>Channel details</h5>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum
                                                        doloremque perferendis facilis quisquam beatae cumque temporibus
                                                        maiores corrupti dolor odit, eveniet ab distinctio quos itaque.
                                                    </p>
                                                    <ul class="channel_details_list">
                                                        <li>
                                                            <span><TfiWorld /></span>
                                                            <Link to="">https://supportindiaapp.com/channel/movies</Link>
                                                        </li>
                                                        <li>
                                                            <span>
                                                            <LuBellRing />
                                                            </span>
                                                            28.5M subscribers
                                                        </li>
                                                        <li>
                                                            <span>
                                                            <ImFilm />
                                                            </span>
                                                            628 videos
                                                        </li>
                                                        <li>
                                                            <span>
                                                            <MdOutlinePreview />
                                                            </span>
                                                            2,403,800 views
                                                        </li>
                                                        <li>
                                                            <span>
                                                            <BsPatchExclamation />
                                                            </span>
                                                            Joined July 13, 2024
                                                        </li>
                                                        <li>
                                                            <span>
                                                            <GiIndiaGate />
                                                            </span>
                                                            India
                                                        </li>
                                                    </ul>
                                                    <button class="btn primary-btn" title="Play Now"> <i
                                                            class="fa-solid fa-share"></i> Share channel</button>


                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <ChannelProfile/>
                        </TabPanel>
                        <TabPanel>
                            <History/>
                        </TabPanel>
                        <TabPanel>
                            <Yourvideos/>
                        </TabPanel>
                        <TabPanel>
                            <Settings/>
                        </TabPanel>
                    </Col>
                </Row>
            </Tabs>
            </div>
        </section>
    </>
  )
}
