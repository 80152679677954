import React from 'react'
import { Row , Col} from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Account() {
  return (
    <>
        <Row className='align-items-center'>
            <Col sm={12}>
                <div class="contentFilterwrapper"><span>Choose how you appear and what you see on SupportIndia</span></div>
                <p className='signPara'>Signed in as rajib@mychannel.com</p>
            </Col>
              <Col sm={6} lg={5} xl={4}>
                  <div className='AccountCard'>
                      <div className='cardheader'>
                          <b>Your SupportIndia channel</b>
                          <p>This is your public presence on SupportIndia. You need a channel to upload your own videos, comment on videos, or create playlists.</p>
                      </div>
                      <div className='cardbody'>
                          <Link to={""} className='yourchannelDp'><img src="https://cdn.pixabay.com/photo/2024/07/22/06/53/woman-8911962_1280.jpg" loading='lazy' alt='' className='img-fluid' /></Link>
                          <p className='yourchannelName'>Happy Vlogging</p>

                          <div className='btnWrapper'>
                            <Link to={""} class="btn primary-btn" title="Play Now">Channel status and features</Link>
                            <Link to={""} class="btn primary-btn" title="Play Now">Create a new channel</Link>
                            <Link to={""} class="btn primary-btn" title="Play Now">View advanced settings</Link>
                          </div>
                      </div>
                  </div>
              </Col>
              <Col sm={6} lg={7} xl={8}>
                <div className='accountYouraccount'>
                <div className='cardheader'>
                    <b>Your account</b>
                    <p>You sign in to SupportIndia with your Google Account</p>
                </div>

                    <div className='settingItem'>
                        <b> Account</b>
                        <div className='settingContent'>
                            <div><Link to={""}>View or change your  Account settings</Link></div>
                            <small>You will be redirected to your  Account page</small>
                        </div>
                    </div>

                    <div className='settingItem'>
                        <b>Membership</b>
                        <div className='settingContent'>
                            <div><span>No membership</span><Link to={"/subscribe"}>View or change your  Account settings</Link></div>
                            <small> Premium offers Premium videos, and more</small>
                        </div>
                    </div>
                </div>
              </Col>
        </Row>
    </>
  )
}
