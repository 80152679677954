import React, { useState,useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import './channelprofile.css'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import { TiTick } from "react-icons/ti";
import { IoSearch } from "react-icons/io5";

import Myreels from './Myreels';
import Myvod from './Myvod';


import Myvideos from './Myvideos';




import { IoIosMore } from "react-icons/io";
import { AiOutlineLike } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
import { FaRegComments } from "react-icons/fa";

import Modal from 'react-bootstrap/Modal';

import Pinvideo from '../channelprofile/pinVideo'
//import channelData from '../../data/channel-education.json'
import channelPlaylist from '../../data/channelplaylist.json'
//import channelReel from '../../data/channelreel-edu.json'
import channelVideo from '../../data/channelvideo.json'
import { LazyLoadImage } from "react-lazy-load-image-component";

import Playlistdata from '../../data/Playlistdata'
import Myplaylist  from './Myplaylist';
import {useParams} from 'react-router-dom';








export default function Channelprofile() {
    const [modalShow, setModalShow] = useState(false);
    const {channelId} = useParams();


    const [channelData, setChannelData] = useState([]);
   
  
  useEffect(() => {
    import(`../../data/channel-${channelId}.json`)
      .then((res) => setChannelData(res.default))
      .catch(_ => null);
  }, []);







    const [active, setactive] = useState("latest");
    const latesthandleClick = () => {
        setactive("latest")
    }
    const popularhandleClick = () => {
        setactive("popular")
    }
    const olderhandleClick = () => {
        setactive("older")
    }
    const [activereel, setactivereel] = useState("latestReel");
    const latestreelhandleClick = () => {
        setactivereel("latestReel")
    }
    const popularreelhandleClick = () => {
        setactivereel("popularReel")
    }
    const olderreelhandleClick = () => {
        setactivereel("olderReel")
    }
    return (
        <>
            <section className='channelBody'>
                {
                    channelData.map((channelvalue) => {
                        return (
                            <div className="userCover" style={{ backgroundImage: `url(${channelvalue.coverImg})` }}></div>
                        )

                    })
                }
                <Container>
                    <Row>
                        <Col sm={12}>
                            {
                                channelData.map((channelvalue) => {

                                    return (<div className="dpwrapper">
                                        <div className="ChannelDetails" style={{ color: "var(--white)" }}>
                                            <div className="channelDp">



                                                <LazyLoadImage title={channelvalue.userImgTitle} className="img-fluid" src={channelvalue.userImg}
                                                    placeholderSrc={channelvalue.userImg}
                                                    alt={channelvalue.userImgTitle}
                                                    effect="blur"
                                                    wrapperProps={{
                                                        // If you need to, you can tweak the effect transition using the wrapper style.
                                                        style: { transitionDelay: "1s" },
                                                    }}

                                                />



                                            </div>
                                            <div className="channelInfo">
                                                <h1><span className="channelName">{channelvalue.channelName}</span> <span className="verified" title="verified" data-toggle="tooltip" data-placement="top" data-bs-original-title="verified" aria-label="verified"><TiTick /></span><span className="spcl-badge" title="spcl-badge" data-toggle="tooltip" data-placement="top" data-bs-original-title="spcl-badge" aria-label="spcl-badge">{channelvalue.badges}</span></h1>
                                                <ul className="handleInfo">
                                                    <li>@{channelvalue.userName}</li>
                                                    <li>{channelvalue.subscribers} subscribers</li>
                                                    <li>{channelvalue.videos} videos</li>
                                                    <li>{channelvalue.reels} reels</li>
                                                    <li onClick={() => setModalShow(true)}><span className="loginbtn">More Info</span></li>
                                                </ul>
                                                <Link to={""} className="btn primary-btn subscribeBtn" title="Subscribe">Subscribe</Link>



                                                <Modal

                                                    size="lg"
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    centered show={modalShow}
                                                    onHide={() => setModalShow(false)}
                                                >
                                                    <Modal.Header closeButton>

                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p className='h5'><b>About</b></p>
                                                        <p>
                                                            {channelvalue.channelDetails}
                                                        </p>
                                                        <hr />
                                                        <p className='h5'><b>Channel details</b></p>
                                                        <p>
                                                            Link: {channelvalue.channelLink}
                                                        </p>
                                                        <p>
                                                            Email: {channelvalue.userEmail}
                                                        </p>
                                                        <p>
                                                            Joined: {channelvalue.joineddate}
                                                        </p>
                                                        <p>
                                                            Location: {channelvalue.location}
                                                        </p>
                                                        <p>
                                                            Like: {channelvalue.like}
                                                        </p>

                                                    </Modal.Body>

                                                </Modal>










                                            </div>
                                        </div>
                                    </div>)

                                })
                            }

                        </Col>
                    </Row>
                </Container>
                <div className="channelWrapper">
                    <Tabs>
                        <div className="ctabHeader">
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <div id="userChannel">
                                            <TabList>
                                                <Tab>Home</Tab>
                                                <Tab>Videos</Tab>
                                                {/* <Tab>Reels</Tab>
                                                <Tab>Ott</Tab>
                                                <Tab>Playlist</Tab>
                                                <Tab>Community</Tab> */}
                                            </TabList>

                                            <div className="searchbtn" id="searchbtn" title="Search"><IoSearch /></div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className='ctabBody'>
                            <Container>
                                <Row>
                                    <Col sm={12}>
                                        <TabPanel>




                                            {
                                                channelData.map((channelvalue) => {
                                                    return (<Pinvideo pinVideo={channelvalue.pinVideo} pinVideoTitle={channelvalue.pinVideoTitle} pinVideoDesc={channelvalue.pinVideoDesc} pinVideoLink={channelvalue.pinVideoLink} pinVideoViews={channelvalue.pinVideoViews} pinVideoUploadTime={channelvalue.pinVideoUploadTime} />)
                                                })}
                                            <Myvod />
                                            <Myreels />

                                        </TabPanel>
                                        <TabPanel>
                                            <Row>
                                                <div className="col-sm-12">
                                                    <div className="filterBar">
                                                        <b>Filter By :</b>
                                                        <ul>
                                                            <li><span className={active === "latest" ? "filterTag" : ""} onClick={latesthandleClick}>Latest</span></li>
                                                            <li><span className={active === "popular" ? "filterTag" : ""} onClick={popularhandleClick}>Popular</span></li>
                                                            <li><span className={active === "older" ? "filterTag" : ""} onClick={olderhandleClick}>Oldest</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Row>
                                            {active === "latest" && (<div className='latestvideos'>
                                                <Row>
                                                    <Myvideos tab="latest" category="channelId" />

                                                </Row>
                                            </div>)}

                                            {active === "popular" && (<div className='popularvideos'>
                                                <Row>
                                                    <Myvideos tab="popular" category="channelId" />
                                                </Row>
                                            </div>)}
                                            {active === "older" && (<div className='oldervideos'>
                                                <Row>
                                                    <Myvideos tab="older" category="channelId" />
                                                </Row>
                                            </div>)}

                                        </TabPanel>
                                        <TabPanel>

                                        <Row>
                                                <div className="col-sm-12">
                                                    <div className="filterBar">
                                                        <b>Filter By :</b>
                                                        <ul>
                                                            <li><span className={activereel === "latestReel" ? "filterTag" : ""} onClick={latestreelhandleClick}>Latest</span></li>
                                                            <li><span className={activereel === "popularReel" ? "filterTag" : ""} onClick={popularreelhandleClick}>Popular</span></li>
                                                            <li><span className={activereel === "olderReel" ? "filterTag" : ""} onClick={olderreelhandleClick}>Oldest</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Row>
                                            {activereel === "latestReel" && (<div className='latestReel'>
                                                <Row>
                                                    <Myreels tab="latestReel" />

                                                </Row>
                                            </div>)}

                                            {activereel === "popularReel" && (<div className='popularReel'>
                                                <Row>
                                                    <Myreels tab="popularReel" />
                                                </Row>
                                            </div>)}
                                            {activereel === "olderReel" && (<div className='olderReel'>
                                                <Row>
                                                    <Myreels tab="olderReel" />
                                                </Row>
                                            </div>)}




                                          
                                        </TabPanel>
                                        <TabPanel>
                                            <div className='ottArea'>
                                                <Row>
                                                    <Myvod tab="ott" />
                                                </Row>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className='playlistarea'>
                                                <Row>
                                                    {
                                                        Playlistdata.map((poddata) => {
                                                            return (

                                                                <Myplaylist classcss={"col-sm-6 col-md-3 col-lg-3"}  podcastThumb={poddata.podcastThumb} podcustLink={"/podcust"}  episodeNo={poddata.episodeNo}  podcastName={poddata.podcastName} />
                                                                
                                                            )
                                                        })
                                                    }
                                                </Row>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className='communityArea'>
                                                <Row>
                                                    <div className="col-sm-6">
                                                        <div className="communityItem">
                                                            <div className="cHeader">
                                                                <div className="CUser">
                                                                    <Link to="" className="cDp">
                                                                        <img src="https://perfectpixelweb.com/profile/assets/img/me.jpg" alt="" loading="lazy" />
                                                                    </Link>
                                                                    <div className="cInfo">
                                                                        <Link to="">JOHN DOE</Link>
                                                                        <p>2 months ago</p>
                                                                    </div>
                                                                </div>
                                                                <div className="moreBtn"><IoIosMore /></div>
                                                            </div>
                                                            <div className="cBody">
                                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse suscipit, accusamus, aliquam maiores rerum labore possimus similique sed cumque cupiditate sunt pariatur sit libero deleniti quidem nulla exercitationem. Quis officia harum fugit deserunt eius, unde laborum.</p>

                                                                <Link to="" className="cImg">
                                                                    <img src="https://yt3.ggpht.com/wnJl4_IwG5yTX8vvyb_OwrtdKMzEX4-5AC9XDxvWogCuAZTAgoWJirEBav_dPwfavlc7WtXKRCxKfQ=s640-c-fcrop64=1,00000000ffffffff-rw-nd-v1" loading="lazy" alt="" />
                                                                </Link>

                                                                <div className="bannerBtns">
                                                                    <div className="loginbtn" title="Like" data-toggle="tooltip" data-bs-original-title="Like"><AiOutlineLike />97 K</div>

                                                                    <div className="loginbtn" title="Like" data-toggle="tooltip" data-bs-original-title="Like"><AiOutlineDislike /></div>

                                                                    <div className="loginbtn" title="Like" data-toggle="tooltip" data-bs-original-title="Like"><FaRegComments />2.9 K</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="communityItem">
                                                            <div className="cHeader">
                                                                <div className="CUser">
                                                                    <Link to="" className="cDp">
                                                                        <img src="https://perfectpixelweb.com/profile/assets/img/me.jpg" alt="" loading="lazy" />
                                                                    </Link>
                                                                    <div className="cInfo">
                                                                        <Link to="">JOHN DOE</Link>
                                                                        <p>2 months ago</p>
                                                                    </div>
                                                                </div>
                                                                <div className="moreBtn"><IoIosMore /></div>
                                                            </div>
                                                            <div className="cBody">
                                                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse suscipit, accusamus, aliquam maiores rerum labore possimus similique sed cumque cupiditate sunt pariatur sit libero deleniti quidem nulla exercitationem. Quis officia harum fugit deserunt eius, unde laborum.</p>

                                                                <Link to="" className="cImg">
                                                                    <img src="https://yt3.ggpht.com/wnJl4_IwG5yTX8vvyb_OwrtdKMzEX4-5AC9XDxvWogCuAZTAgoWJirEBav_dPwfavlc7WtXKRCxKfQ=s640-c-fcrop64=1,00000000ffffffff-rw-nd-v1" loading="lazy" alt="" />
                                                                </Link>

                                                                <div className="bannerBtns">
                                                                    <div className="loginbtn" title="Like" data-toggle="tooltip" data-bs-original-title="Like"><AiOutlineLike />97 K</div>

                                                                    <div className="loginbtn" title="Like" data-toggle="tooltip" data-bs-original-title="Like"><AiOutlineDislike /></div>

                                                                    <div className="loginbtn" title="Like" data-toggle="tooltip" data-bs-original-title="Like"><FaRegComments />2.9 K</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </TabPanel>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Tabs>
                </div>
            </section>




        </>
    )
}
