import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import SectionTitle from '../../includes/sectionTitle/SectionTitle';


import thumb1 from '../../images/recently/recent1.jpg'
import { Link } from 'react-router-dom';

import { IoMdClose } from "react-icons/io";
import Optiondropdown from './Optiondropdown';

import { LuClock4 } from "react-icons/lu";
import { FaPlay } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { FaRegTrashCan } from "react-icons/fa6";
import { RiPauseLargeLine } from "react-icons/ri";
import { MdManageHistory } from "react-icons/md";


export default function History() {
    const [isFocused, setIsFocused] = useState(false);
    const containerRef = useRef(null);

      // Function to handle clicks inside the component
        const handleFocus = () => {
            setIsFocused(true);
        };
    
          // Function to handle clicks outside the component
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsFocused(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);


  return (
    <>
        <div className='historyWrap'>
        <Row>
            <Col sm={12}>
                <div className='tabTitle'><span>Watch history</span></div>
            </Col>
            <Col sm={7} lg={8} xl={9}>
                <SectionTitle stitle="Yesterday"/>

                <div className='historyItem'>
                    <div className='thumbWrapper'>
                        <Link className='historyThumb' to={''}>
                            <img src={thumb1} className='img-fluid' alt='' loading='lazy' />
                        </Link>
                        <div className='hisOverley'>
                            <Button className='hisOverleyBtn'><FaPlay/>Play Now</Button>
                            <Button className='hisOverleyBtn'><LuClock4/>Watch Later</Button>
                        </div>
                    </div>
                    <div className='historyInfo'>
                        <div className='infoDetail'>
                            <Link to={""} className='videoName'>Audio Play Pause and Stop using JavaScript | Play Audio using JavaScript</Link>
                            <ul className='tagListing'>
                                <li><small>Invention Tricks</small></li>
                                <li><small>Invention Tricks</small></li>
                            </ul>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <div className='historyOption'>
                            <div className='hBtn clsBtn'><IoMdClose/></div>
                            <Optiondropdown/>
                        </div>
                    </div>
                </div>
                <div className='historyItem'>
                    <div className='thumbWrapper'>
                        <Link className='historyThumb' to={''}>
                            <img src={thumb1} className='img-fluid' alt='' loading='lazy' />
                        </Link>
                        <div className='hisOverley'>
                            <Button className='hisOverleyBtn'><FaPlay/>Play Now</Button>
                            <Button className='hisOverleyBtn'><LuClock4/>Watch Later</Button>
                        </div>
                    </div>
                    <div className='historyInfo'>
                        <div className='infoDetail'>
                            <Link to={""} className='videoName'>Audio Play Pause and Stop using JavaScript | Play Audio using JavaScript</Link>
                            <ul className='tagListing'>
                                <li><small>Invention Tricks</small></li>
                                <li><small>Invention Tricks</small></li>
                            </ul>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <div className='historyOption'>
                            <div className='hBtn clsBtn'><IoMdClose/></div>
                            <Optiondropdown/>
                        </div>
                    </div>
                </div>

                <SectionTitle stitle="Wednesday"/>
                <div className='historyItem'>
                    <div className='thumbWrapper'>
                        <Link className='historyThumb' to={''}>
                            <img src={thumb1} className='img-fluid' alt='' loading='lazy' />
                        </Link>
                        <div className='hisOverley'>
                            <Button className='hisOverleyBtn'><FaPlay/>Play Now</Button>
                            <Button className='hisOverleyBtn'><LuClock4/>Watch Later</Button>
                        </div>
                    </div>
                    <div className='historyInfo'>
                        <div className='infoDetail'>
                            <Link to={""} className='videoName'>Audio Play Pause and Stop using JavaScript | Play Audio using JavaScript</Link>
                            <ul className='tagListing'>
                                <li><small>Invention Tricks</small></li>
                                <li><small>Invention Tricks</small></li>
                            </ul>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <div className='historyOption'>
                            <div className='hBtn clsBtn'><IoMdClose/></div>
                            <Optiondropdown/>
                        </div>
                    </div>
                </div>
                <div className='historyItem'>
                    <div className='thumbWrapper'>
                        <Link className='historyThumb' to={''}>
                            <img src={thumb1} className='img-fluid' alt='' loading='lazy' />
                        </Link>
                        <div className='hisOverley'>
                            <Button className='hisOverleyBtn'><FaPlay/>Play Now</Button>
                            <Button className='hisOverleyBtn'><LuClock4/>Watch Later</Button>
                        </div>
                    </div>
                    <div className='historyInfo'>
                        <div className='infoDetail'>
                            <Link to={""} className='videoName'>Audio Play Pause and Stop using JavaScript | Play Audio using JavaScript</Link>
                            <ul className='tagListing'>
                                <li><small>Invention Tricks</small></li>
                                <li><small>Invention Tricks</small></li>
                            </ul>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <div className='historyOption'>
                            <div className='hBtn clsBtn'><IoMdClose/></div>
                            <Optiondropdown/>
                        </div>
                    </div>
                </div>
                <div className='historyItem'>
                    <div className='thumbWrapper'>
                        <Link className='historyThumb' to={''}>
                            <img src={thumb1} className='img-fluid' alt='' loading='lazy' />
                        </Link>
                        <div className='hisOverley'>
                            <Button className='hisOverleyBtn'><FaPlay/>Play Now</Button>
                            <Button className='hisOverleyBtn'><LuClock4/>Watch Later</Button>
                        </div>
                    </div>
                    <div className='historyInfo'>
                        <div className='infoDetail'>
                            <Link to={""} className='videoName'>Audio Play Pause and Stop using JavaScript | Play Audio using JavaScript</Link>
                            <ul className='tagListing'>
                                <li><small>Invention Tricks</small></li>
                                <li><small>Invention Tricks</small></li>
                            </ul>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <div className='historyOption'>
                            <div className='hBtn clsBtn'><IoMdClose/></div>
                            <Optiondropdown/>
                        </div>
                    </div>
                </div>
                <div className='historyItem'>
                    <div className='thumbWrapper'>
                        <Link className='historyThumb' to={''}>
                            <img src={thumb1} className='img-fluid' alt='' loading='lazy' />
                        </Link>
                        <div className='hisOverley'>
                            <Button className='hisOverleyBtn'><FaPlay/>Play Now</Button>
                            <Button className='hisOverleyBtn'><LuClock4/>Watch Later</Button>
                        </div>
                    </div>
                    <div className='historyInfo'>
                        <div className='infoDetail'>
                            <Link to={""} className='videoName'>Audio Play Pause and Stop using JavaScript | Play Audio using JavaScript</Link>
                            <ul className='tagListing'>
                                <li><small>Invention Tricks</small></li>
                                <li><small>Invention Tricks</small></li>
                            </ul>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <div className='historyOption'>
                            <div className='hBtn clsBtn'><IoMdClose/></div>
                            <Optiondropdown/>
                        </div>
                    </div>
                </div>

                <SectionTitle stitle="Jun 18"/>
                <div className='historyItem'>
                    <div className='thumbWrapper'>
                        <Link className='historyThumb' to={''}>
                            <img src={thumb1} className='img-fluid' alt='' loading='lazy' />
                        </Link>
                        <div className='hisOverley'>
                            <Button className='hisOverleyBtn'><FaPlay/>Play Now</Button>
                            <Button className='hisOverleyBtn'><LuClock4/>Watch Later</Button>
                        </div>
                    </div>
                    <div className='historyInfo'>
                        <div className='infoDetail'>
                            <Link to={""} className='videoName'>Audio Play Pause and Stop using JavaScript | Play Audio using JavaScript</Link>
                            <ul className='tagListing'>
                                <li><small>Invention Tricks</small></li>
                                <li><small>Invention Tricks</small></li>
                            </ul>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <div className='historyOption'>
                            <div className='hBtn clsBtn'><IoMdClose/></div>
                            <Optiondropdown/>
                        </div>
                    </div>
                </div>
                <div className='historyItem'>
                    <div className='thumbWrapper'>
                        <Link className='historyThumb' to={''}>
                            <img src={thumb1} className='img-fluid' alt='' loading='lazy' />
                        </Link>
                        <div className='hisOverley'>
                            <Button className='hisOverleyBtn'><FaPlay/>Play Now</Button>
                            <Button className='hisOverleyBtn'><LuClock4/>Watch Later</Button>
                        </div>
                    </div>
                    <div className='historyInfo'>
                        <div className='infoDetail'>
                            <Link to={""} className='videoName'>Audio Play Pause and Stop using JavaScript | Play Audio using JavaScript</Link>
                            <ul className='tagListing'>
                                <li><small>Invention Tricks</small></li>
                                <li><small>Invention Tricks</small></li>
                            </ul>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <div className='historyOption'>
                            <div className='hBtn clsBtn'><IoMdClose/></div>
                            <Optiondropdown/>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={5} lg={4} xl={3}>
                <div className='historyfilters'>
                    <Form>
                          <Form.Group className="mb-3" controlId="searchhistory">
                              <div className={`historySearch ${isFocused ? 'focus' : ''}`} onClick={handleFocus} ref={containerRef}>
                                <Form.Control type="email" placeholder="Search Watch History" />
                                <div className='searchIcon'><IoSearch/></div>
                              </div>
                          </Form.Group>
                    </Form>
                    <ul>
                        <li><div><FaRegTrashCan/> Clear all watch history</div></li>
                        <li><div><RiPauseLargeLine /> Pause watch history</div></li>
                        <li><div><MdManageHistory/> Manage all  history</div></li>
                    </ul>
                </div>       
            </Col>
        </Row>
        </div>

    </>
  )
}
