import React, { useState, useEffect   } from 'react'
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import useClipboard from "react-use-clipboard";

import { IoMdAddCircleOutline } from "react-icons/io";
import { MdContentCopy } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";

export default function Step1({uploadfile}) {
  const [switchValue, setswitchValue] = useState(false);

  const handleSwitch =()=>{
    setswitchValue((prevalue)=>{
      const newValue = !prevalue
      if(newValue == true){
        alert("kids Protection on")
      }
      else {
        alert("kids Protection off")
      }
      return newValue;
    })
  }

    // State to hold the selected image
    const [image, setImage] = useState(null);
    const [showdiv, setShowdiv] = useState(false)

    // Function to handle the image upload
    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        setImage(imageUrl);
        setShowdiv(true)
      }
    };

    // const [buttonIcon, setBtnIcon] =useState(false);

    const [textTocopy, setTexttoCopy] = useState('');
    const [isCopied, setCopied] = useClipboard(textTocopy);

    const copyfunction = () => {
        const videoLinkText = document.getElementById('videoLink').innerText;
        setTexttoCopy(videoLinkText); // Update the state first
    };

    useEffect(() => {
        if (textTocopy) {
            setCopied(); // Copy to clipboard after the state is updated
            // alert(textTocopy);
        }
    }, [textTocopy, setCopied]);


  return (
    <>
      <Row>
          <Col sm={12} md={7} lg={8}>
            <form className='formElement detailsForm'>
              <div class="form-group">
                <label for="" className='formLabel'>Title (required)</label>
                <input type="text" placeholder="Enter Video Title" class="form-control" />
              </div>

              <div class="form-group">
                <label for="" className='formLabel'>Description (required)</label>
                <textarea type="text" placeholder="Enter Video Description" class="form-control" rows="8" ></textarea>
              </div>

              <div class="form-group mb-0">
                <ul className='formOption'>
                  <li>
                    <Form.Check className={`protection ${switchValue==true? 'on': 'off'}`}
                      type="switch"
                      id="childrenlock"
                      label={`${switchValue==true? 'On Kid Protection': 'Off Kid Protection'}`}
                      onChange={handleSwitch}
                    />
                  </li>
                  <li>
                    <div className='ageFilter'>
                      <label>Age Filter :</label>
                      <Form.Select aria-label="Default select example" className='form-control'>
                        <option value="All">All</option>
                        <option value="2">Above 18</option>
                        <option value="3">Above 40</option>
                      </Form.Select>
                    </div>
                  </li>
                  <li>
                    <div className='addThumbnail'>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        style={{ display: "none" }}
                        id="image-upload"
                      />
                      <label htmlFor="image-upload">
                        <IoMdAddCircleOutline/> Add Thumbnail
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </form>
          </Col>
          <Col sm={12} md={5} lg={4}>
            <div className='previewCard'>
              <div className='previewImg'>
                <video src={uploadfile} autoPlay='true' controls style={{ width: "100%",  display: `${showdiv== true ? 'none': 'block'}` }}></video>
                <img src={image} alt="Uploaded Preview" style={{ width: "100%",  display: `${showdiv== true ? 'block': 'none'}` }} />
              </div>
              <div className='previewDetails'>
                <div className='preInfo'>
                  <small>Video Link</small>
                  <strong className='videoLink' id='videoLink'>New text</strong>
                  <button className={isCopied === false ? 'copyBtn ripple' : 'copyBtn ripple success'} onClick={copyfunction}>{isCopied === false ? <MdContentCopy /> : <IoMdCheckmark />}</button>
                  {/* <button className='copyBtn ripple' onClick={copyHandleClick}>{buttonIcon === false ? <MdContentCopy /> : <IoMdCheckmark />}</button> */}
                </div>

                <div className='preInfo mt-3'>
                  <small>Filename</small>
                  <strong>{uploadfile}</strong>
                </div>
              </div>
            </div>
          </Col>
        </Row>
    </>
  )
}
