import React from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';

//import placeholder from '../../images/placeholder.svg'

export default function Videolisttemp3(props) {
  return (
    <>
          
          <div className={props.classValue}>

<Link to={props.videoLink} className="classItem" title={props.Title}>
    <div className="classItemimg">   
                    
                    <LazyLoadImage  src={props.Img}
              placeholderSrc={props.Img}
              alt={props.ImgAlt}
              effect="blur"
              className="img-fluid"
              wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
              }}

          />


              </div>
    <div className="classDetail">
        <b className="className">{props.Title}</b>

        <p>{props.shortDetail}</p>

       
    </div>
</Link>
</div>

    </>
  )
}









