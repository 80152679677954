import React from 'react';
import '../vijaypath/vijaypath.css';

import {Link, useParams} from 'react-router-dom';
import catbg from '../../images/catbg.jpg';
import Breadcumb from '../../components/breadcumb/Breadcumb';
import { Col, Row } from 'react-bootstrap';
import SectionTitle from '../../includes/sectionTitle/SectionTitle';
import { AiFillLike } from "react-icons/ai";

export default function Yodha() {
   // const {catName} = useParams();
  return (
    <>
        <section className='catBanner' style={{backgroundImage: `url(${catbg})`}}>
          <div className='container-fluid'>
            <Row>
              <Col sm={12}><Breadcumb pageTitle={"Yodha"} /></Col>
            </Row>
          </div>
        </section>

        <section className='yodhaSec'>
            <div className='container'>
                <Row>
                    <Col sm={12}>
                        <SectionTitle stitle="Yodha"/>
                    </Col>
                </Row>

                <Row>
                      <Col sm={12}>
                          <div className='yodhaWrapper'>
                            <div class="danveerItem">
                                <div class="dIcon">
                                    <img width="64" height="64" src="https://img.icons8.com/dotty/64/ff5722/donate.png" alt="donate"/>
                                </div>
                                <div class="dInfo">
                                        <p>First get membership by <b>Rs.275/-</b> then ID generate </p>
                                </div> 
                            </div>

                            <div class="danveerItem">
                                <div class="dIcon">
                                    <img width="64" height="64" src="https://img.icons8.com/pastel-glyph/64/ff5722/groups--v2.png" alt="groups--v2" />
                                </div>
                                <div class="dInfo">
                                    <p>You have to make <b>20 DANVEER</b> members in your channel</p>
                                </div>
                            </div>

                              <div class="danveerItem">
                                  <div class="dIcon">
                                      <img width="64" height="64" src="https://img.icons8.com/ios/64/ff5722/alarm--v1.png" alt="alarm--v1" />
                                  </div>
                                  <div class="dInfo">
                                      <p><b>3 month</b>  subscription free</p>
                                  </div>
                              </div>

                              <div class="danveerItem">
                                  <div class="dIcon">
                                      <img width="64" height="64" src="https://img.icons8.com/ios/64/ff5722/cinema---v1.png" alt="cinema---v1" />
                                  </div>
                                  <div class="dInfo">
                                      <ul>
                                          <li>Make videos</li>
                                          <li>share the social media platform</li>
                                          <li>collect like his/her video in support india apps.</li>
                                      </ul>
                                  </div>
                              </div>

                              <div class="danveerItem">
                                  <div class="dIcon">
                                    <img width="64" height="64" src="https://img.icons8.com/ios/64/ff5722/portfolio.png" alt="portfolio"/>
                                  </div>
                                  <div class="dInfo">
                                      <p>self employment proposal</p>
                                  </div>
                              </div>
                          </div>

                      </Col>
                </Row>

                <Row>
                <Col sm={12}>
                  <p className='sectionBreak'><span>Prize for winner</span></p>
                </Col>

                <Col sm={12} md={6}></Col>
                <Col sm={12} md={6}>
                  <div className='prizeInfo'>
                    <h2>Exclusive Rewards for the Top Performer</h2>

                    <ul>
                      <li>1st top <b>100 person, sort by maximum like</b> will get 3 lakh</li>
                      <li>2nd top <b>100 person, sort by maximum like</b> will get scooty with organic vegetable cart and
                      3 pcs Aeroponic tower. </li>
                      <li>3rd top <b>1000 person, sort by maximum like</b> will get 1 pcs Aeroponic Tower</li>
                      <li>4th top <b>1000 person, sort by maximum like</b> will get 1 pcs sewing machine</li>
                      <li>5th top <b>5000 person, sort by maximum like</b> will get cottage industry material like handwash & dishwash</li>
                    </ul>

                    <small>Note - It is informed that here the Yoddha and Danveer candidate will be judged according to the maximum likes on
                    their videos and price money will be given accordingly to the video which will have the most likes.</small>
                    <div className='prizeBtnWrap'>
                      
                      <Link to={''} class="btn primary-btn" title="Play Now">MY DANVEER GROUP</Link>
                      <Link to={''} class="btn primary-btn" title="Play Now">MY REWARD</Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
        </section>
    </>
  )
}
