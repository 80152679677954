import React from 'react';

import { useState, useEffect, useRef} from 'react';

import { Link,NavLink } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";

import Canvasmenu from './Canvasmenu';
import Categorydata from '../../data/category.json';

export default function Categories() {


 //=====dropdown
 const [isDropdownVisible, setIsDropdownVisible] = useState(false);
 const moreIconRef = useRef(null);
 const dropDownRef = useRef(null);

 const handleDocumentClick = (event) => {
   if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
     setIsDropdownVisible(false);
   }
 };

 const handleMoreIconClick = (event) => {
   setIsDropdownVisible(!isDropdownVisible);
   event.stopPropagation();
 };

 const handleDropdownClick = (event) => {
  setIsDropdownVisible(false);
   event.stopPropagation();

 };

 useEffect(() => {
   document.addEventListener('click', handleDocumentClick);

   return () => {
     document.removeEventListener('click', handleDocumentClick);
   };
 }, []);
//////////////

    return (
        <>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                    <NavLink  className="nav-link" aria-current="page" to={"/"} title="Home">Home</NavLink>
                </li>
                <li className="nav-item">
                        <div  className={`moreIcon ${isDropdownVisible ? 'activeBtn' : ''}`} title="More" id="moreIcon" onClick={handleMoreIconClick}
                            ref={moreIconRef} style={{width:'120px'}}>
                            VIJAYPATH <IoIosArrowDown />
                        </div>
                        <ul className={`dropDown ${isDropdownVisible ? 'show-drop' : ''}`} id="dropDown" onClick={handleDropdownClick}
                            ref={dropDownRef}>
                            <li><Link to={"/vijaypath"}> Vijaypath</Link></li>
                            <li><Link to={"/offers"}> Offers</Link></li>
                            <li><Link to={"/danveer"}> Danveer</Link></li>
                            <li><Link to={"/yodha"}> Yodha</Link></li>
                            
                        </ul>
                  </li>
                {
                    Categorydata.map((cat, index) => {
                        if (index < 4) {
                            return (<li className="nav-item" key={cat.id}>
                                <NavLink activeclassname="active" className={({isActive}) => (isActive ? "nav-link active" : "nav-link")}  to={cat.link.toLowerCase()} title={cat.name}>{cat.name}</NavLink>
                            </li>)
                        }
                    })
                }
               
               <li id="moreIcon" className="d-none d-xl-block" style={{color:"var(--white)"}} ><Canvasmenu /></li>

            </ul>


           

        </>
    );
}
