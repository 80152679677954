import React, { useRef } from 'react';
import {Row, Col, Button}  from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-toastify/dist/ReactToastify.css';

import { FaRegCopy } from "react-icons/fa";
import { GrChannel } from "react-icons/gr";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineMoveUp } from "react-icons/md";
import { MdOutlineDeleteSweep } from "react-icons/md";

export default function AdvanceSetting() {
    const userId = "uKr3p65H_c1uG6kXBkOWRQ";
    const channelId = "uKr3p65H_c1uG6kXBkOWRQ";
    const danveerlId = "uKr3pdsfdsuG6kXBkOWRQ";

    const notify = (message) => toast(message);

  return (
    <>
        <Row>
            <Col sm={12}>
                <div class="contentFilterwrapper"><span>Set up SupportIndia exactly how you want it</span></div>
                <p className='signPara'>Advanced settings</p>
            </Col>
        </Row>
        <Row>
            <Col sm={6} md={6} xl={3}>
                <div className='AsettingCard'>
                    <div className='AsetIcon'>
                        <FaRegUser/>
                    </div>
                    <b>User ID</b>

                    <Form>
                        <Form.Group >
                            <Form.Control type="text" value={userId} disabled/>
                            <CopyToClipboard text={userId} onCopy={() => notify("User ID copied!")}>
                                    <Button type="button" className="btn primary-btn"><FaRegCopy /> Copy</Button>
                                </CopyToClipboard>
                        </Form.Group>
                    </Form>
                </div>
            </Col>

            <Col sm={6} md={6} xl={3}>
                <div className='AsettingCard'>
                    <div className='AsetIcon'>
                        <GrChannel/>
                    </div>
                    <b>Channel ID</b>

                    <Form>
                        <Form.Group >
                            <Form.Control type="text" value={channelId} disabled />
                            <CopyToClipboard text={channelId} onCopy={() => notify("Channel ID copied!")}>
                                    <Button type="button" className="btn primary-btn"><FaRegCopy /> Copy</Button>
                                </CopyToClipboard>
                        </Form.Group>
                    </Form>
                </div>
            </Col>

            <Col sm={6} md={6} xl={3}>
                <div className='AsettingCard'>
                    <div className='AsetIcon'>
                        <GrChannel/>
                    </div>
                    <b>Danveer ID</b>

                    <Form>
                        <Form.Group >
                            <Form.Control type="text" value={danveerlId} disabled />
                            <CopyToClipboard text={danveerlId} onCopy={() => notify("Danveer ID copied!")}>
                                    <Button type="button" className="btn primary-btn"><FaRegCopy /> Copy</Button>
                                </CopyToClipboard>
                        </Form.Group>
                    </Form>
                </div>
            </Col>

            <Col sm={6} md={6} xl={3}>
                <div className='AsettingCard'>
                    <div className='AsetIcon'>
                        <MdOutlineDeleteSweep/>
                    </div>
                    <b>Delete channel</b>

                    <small>Deleting your YouTube channel won't close your Google Account</small>
                    <Button className='btn primary-btn'>Delete channel</Button>
                </div>
            </Col>
        </Row>

        <ToastContainer />
    </>
  )
}
