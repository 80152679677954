import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/css/style.css';
import { Routes, Route } from "react-router-dom";
import Header from './includes/headerSec/Header';
import Home from './pages/home/Home';
import Footer from './includes/footer/Footer';
//import Agriculturepage from './pages/agriculture/Agriculturepage';
import Singlepage from './pages/singlepage/Singlepage';
import Singlepagevod from './pages/singlepage/Singlepagevod';
import Singlepagereel from './pages/singlepage/Singlepagereel';
import Dashboard from './pages/userDashboard/Dashboard';
import Wallet from './pages/wallet/Wallet';
import Subscribe from './pages/subscribe/Subscribe';
import Categoriepage from './pages/categoriepage/Categoriepage';

import Hastagpage from './pages/hastag/Hastagpage';
import Channelprofile from './pages/channelprofile/Channelprofile';
import Coupon from './components/coupons/Coupon';

import ScrollToTop from "./components/common/Scrolltotop";
import Vijaypath from "./pages/vijaypath/Aboutvijaypath";

import Yodha from "./pages/vijaypath/Yodha";
import Danveer from "./pages/vijaypath/Danveer";
import Offers from "./pages/vijaypath/Offers";

import Results from "./pages/results/Resultspage";

import UploadVideo from "./pages/uploadVideo/UploadVideo";
import Paynow from './pages/paynow/Paynow';
import Privacy from './pages/home/Privacy';
import Terms from './pages/home/Terms';
import Contact from './pages/home/Contact';
import Appdownload from './pages/home/Appdownload';


function App() {
  return (
    <>
     <ScrollToTop />
      <Header/>
        <Routes>
          <Route index element={<Home/>} />
          <Route path="home" element={<Home/>} />
          <Route path="category/:catName" element={<Categoriepage/>} />
          <Route path="hastag/:tagName" element={<Hastagpage/>} />
          {/* <Route path="agriculture" element={<Agriculturepage/>} />   */}
          <Route path="channel/:channelId" element={<Channelprofile/>} />
          <Route path="wallet" element={<Wallet/>} />  
          <Route path="subscribe" element={<Subscribe/>} />  

          <Route path="upload" element={<UploadVideo/>} />  

          <Route path="contact" element={<Contact/>} />  

          <Route path="privacy" element={<Privacy/>} />  

          <Route path="Terms" element={<Terms/>} />  

<Route path="appdownload" element={<Appdownload/>} />
          <Route path="dashboard" element={<Dashboard/>} />
          <Route path="watch/:videoId" element={<Singlepage/>} />
          <Route path="shows/:videoId" element={<Singlepagevod/>} />
          <Route path="coupon" element={<Coupon/>} />
          <Route path="vijaypath" element={<Vijaypath/>} />
          <Route path="yodha" element={<Yodha/>} />
          <Route path="danveer" element={<Danveer/>} />
          <Route path="offers" element={<Offers/>} />
          <Route path="reel/:videoId" element={<Singlepagereel/>} />
          <Route path="results" element={<Results/>} />
          <Route path="paynow" element={<Paynow/>} />
        </Routes>
      <Footer/>
     
    </>
  );
}

export default App;
