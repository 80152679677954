import React from 'react';
import '../wallet/wallet.css';
import bannerMap from '../../images/banner-map.png';
import viewsShape from '../../images/viewsShape.png';
import MySubscribe from './MySubscribe';

import '../subscribe/subscribe.css';
import { Col, Container, Row } from 'react-bootstrap';



export default function Subscribe() {
  return (
    <>
        <section className='subscribe_body subscribePage'>
        <div className='viewsShape'><img src={viewsShape}/></div>
            <div className='bannerMap'><img src={bannerMap} alt=''/></div>
            <Container>
              <Row className='justify-content-center'>
                <Col sm={10} lg={8}>
                <div className='subscribe_area'>
                    <MySubscribe/>
                </div>
                </Col>
              </Row>
            </Container>
        </section>
    </>
  )
}
