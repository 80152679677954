import React, { useState, useEffect } from 'react';



import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import 'react-tabs/style/react-tabs.css';

import landscapeAds from '../../../images/landscapeAds.jpg';
import dot1 from '../../../images/dot1.png';
import shape10 from '../../../images/shape-10.svg';


import SectionTitle from '../../../includes/sectionTitle/SectionTitle';
import Agricultural from './Agricultural';


import Videolist2 from '../../../components/common/Videolisttemp2';
import Videolist3 from '../../../components/common/Reellist';
//import Trendingdata from '../../../data/trending.json';

export default function Education() {
    // const catName = ['agriculture', 'education', 'kids','movies','news','songs','sport'];
    const catName = 'education';

    //const randomElement = catName[Math.floor(Math.random() * catName.length)];  
    const [Trendingdata, setTrendingdata] = useState([]);

    const importData = async () => {
        import(`../../../data/video-${catName}.json`)
            .then((res) => setTrendingdata(res.default))
            .catch(_ => null);
    };

    useEffect(() => {
        importData();
        //importDataReel();
    }, [importData]);


    const [Trendingdatasocial, setTrendingdatasocial] = useState([]);

    const importDatasocial = async () => {
        import(`../../../data/reels-social.json`)
            .then((res) => setTrendingdatasocial(res.default))
            .catch(_ => null);
    };

    useEffect(() => {
        importDatasocial();
        //importDataReel();
    }, [importDatasocial]);



    const options = {
        loop: true,
        margin: 0,
        autoplay: true,
        lazyLoad: true,
        dots: true,
        navText: ['<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>', '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg>'],
        nav: false,
        responsive: {
            0: {
                items: 1.2,
                stagePadding: 15,
            },
            576: {
                items: 5
            },
            992: {
                items: 5
            }
        }

    };


    return (
        <>
            <section className="educationSec neweducationsec">
                <div className="texture_3">
                    <img src={dot1} alt="" title="" loading="lazy" />
                </div>
                <div className="texture_4">
                    <img src={shape10} alt="" title="" loading="lazy" />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-8 col-lg-10">
                            <div className="row">
                                <div className="col-sm-12">
                                    <SectionTitle stitle="Education for You" />
                                </div>
                                {/* <div className="col-sm-12">

                                    <OwlCarousel className='educationSlider owl-theme' {...options}>
                                        {
                                            Trendingdata.map((gridvalue) => {
                                                return (
                                                    <Videolist2 Img={gridvalue.Img} ImgAlt={gridvalue.ImgAlt} Title={gridvalue.Title} shortDetail={gridvalue.shortDetail} userImg={gridvalue.userImg} userImgTitle={gridvalue.userImgTitle} Channel={gridvalue.Channel} videoLink={gridvalue.videoLink} classValue="col-12" />
                                                )
                                            })
                                        }


                                    </OwlCarousel>
                                </div> */}

                                <div className="col-sm-12">
                                    <div className="educationTitle">
                                        <div className="education_title">
                                            <b>All the skills you need in one place</b>
                                            <p>From critical skills to technical topics, we supports your professional development.</p>
                                        </div>
                                    </div>

                                    <div className="educationTab">

                                        <div className="row">
                                            {
                                                Trendingdata.map((videos) => {
                                                    return (
                                                        <Videolist3 Img={videos.Img} ImgAlt={videos.ImgAlt} Title={videos.Title} shortDetail={videos.shortDetail} userImg={videos.userImg} userImgTitle={videos.userImgTitle} Channel={videos.Channel} videoId={"/watch/"+videos.videoId} classValue="col-md-4 pb-3" />
                                                    )
                                                })
                                            }


                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <SectionTitle stitle="Social Work" />
                                </div>
                                <div className='col-sm-12'>
                                    <OwlCarousel className='agriculturalSlider owl-theme' {...options}>
                                        {
                                            Trendingdatasocial.map((ts) => {
                                                return (
                                                    <Videolist3 Img={ts.videoImage} ImgAlt={ts.videotitle} Title={ts.videotitle} shortDetail={ts.videoDesc} userImg={ts.ChannelImage} userImgTitle={ts.ChanelTitle} Channel={ts.ChanelUrl} videoId={"/reel/"+ts.videoId} classValue="col-12" />
                                                )
                                            })
                                        }
                                    </OwlCarousel>

                                </div>
                            </div>
                        </div>


                        <div className="col-sm-4 col-lg-2">
                            <div className="lanscpe_ads">
                                <div className="adsWrapper">
                                    <span className="adTag">Advertisement</span>
                                    <div className="adsImg">
                                        <img src={landscapeAds} alt="ad1" loading="lazy" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
