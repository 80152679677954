import React from 'react';
import '../wallet/wallet.css';

import bannerBg from '../../images/subscribe_bg.jpg';
import Mywallet from './Mywallet';

export default function Wallet() {
  return (
    <>
        <section className='subscribe_body walletSec'>
            <div className='subscribe_content'>
                <div className='subscribe_table'>
                    <Mywallet/>
                </div>
                <div className="subscribe_bg">
                    <div className="bg-img" style={{backgroundImage : `url(${bannerBg})`}}></div>
              </div>
            </div>
        </section>
    </>
  )
}
