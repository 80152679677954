import React from 'react';
import { useState, useEffect } from 'react';
import footLogo from '../../images/loader1.gif';
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { Link } from 'react-router-dom';

export default function Footer() {
    const [activeMenu, setActiveMenu] = useState(null);

    useEffect(() => {
        const handleMenuClick = (event) => {
            setActiveMenu(event.target);
        };

        const navElements = document.querySelectorAll('.f-nav');
        navElements.forEach((el) => el.addEventListener('click', handleMenuClick));

        return () => {
            navElements.forEach((el) => el.removeEventListener('click', handleMenuClick));
        };
    }, []);

    useEffect(() => {
        const navElements = document.querySelectorAll('.f-nav');
        navElements.forEach((el) => el.classList.remove('active-menu'));
        if (activeMenu) {
            activeMenu.classList.add('active-menu');
        }
    }, [activeMenu]);
  return (
    <>
        {/* =====fooeter start===== */}
        <div className="footerSec">
        <div className="footerMain">
            <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6 col-lg-2">
                      <div className="brandbar">
                          <div className="fBrand"><img src={footLogo} alt=""/>Support India</div>
                          <p>At Support India Digital, we offer original, exclusive and premium stories. Everything you want to watch, anytime, anywhere and as much</p>
  
                          <ul className="sharebar">
                              <li><Link to=""><FaFacebookF/></Link></li>
                              <li><Link to=""><FaYoutube/></Link></li>
                              <li><Link to=""><FaXTwitter/></Link></li>
                              <li><Link to=""><FaInstagram /></Link></li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-sm-6 col-lg-2">
                      <div className="footer_content">
                          <b className="footerTitle">COMPANY</b>
                          <ul className="fMenu">
                              <li><Link to="">About Us</Link></li>
                              <li><Link to="">Corporate</Link></li>
                              <li><Link to="">Investor Relations</Link></li>
                              <li><Link to="">Support India  Blog</Link></li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-sm-6 col-lg-2">
                      <div className="footer_content">
                          <b className="footerTitle">CUSTOMER</b>
                          <ul className="fMenu">
                              <li><Link to="">FAQ</Link></li>
                              <li><Link to="">Devices</Link></li>
                              <li><Link to="">Grievance Redressal Mechanism</Link></li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-sm-6 col-lg-2">
                      <div className="footer_content">
                          <b className="footerTitle">CONNECT</b>
                          <ul className="fMenu">
                              <li><Link to="">Contact Us</Link></li>
                              <li><Link to="">Careers</Link></li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-sm-6 col-lg-2">
                      <div className="footer_content">
                          <b className="footerTitle">TOP SI SHOWS</b>
                          <ul className="fMenu">
                              <li><Link to="">Mahabharat</Link></li>
                              <li><Link to="">Jhansi ki Rani</Link></li>
                              <li><Link to="">Kahan ho Tum</Link></li>
                              <li><Link to="">Mahabharat</Link></li>
                              <li><Link to="">Jhansi ki Rani</Link></li>
                              <li><Link to="">Kahan ho Tum</Link></li>
                          </ul>
                      </div>
                  </div>
                  <div className="col-sm-6 col-lg-2">
                      <div className="footer_content">
                          <b className="footerTitle">EXPLORE</b>
                          <ul className="fMenu">
                              <li><Link to="">Latest Shows</Link></li>
                              <li><Link to="">Latest Movies</Link></li>
                              <li><Link to="">Upcoming on hoichoi</Link></li>
                              <li><Link to="">All Shows</Link></li>
                              <li><Link to="">All Movies</Link></li>
                          </ul>
                      </div>
                  </div>
                </div>
          </div>
        </div>
        <div className="copyright">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <small>Copyright © 2024 SupportIndia</small>
                    </div>
                </div>
            </div>
        </div>
      </div>
        {/* =====fooeter start===== */}

     
    </>
  )
}
