import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { FaRegCopyright } from "react-icons/fa6";
import { IoMdCheckmark } from "react-icons/io";

export default function Step3() {
  return (
    <>
      <div className='step3Wrap'>
        <Row>
          <Col sm={12}>
            <Row>
                <Col sm={12} md={8}>
                  <p>We'll check your video for issues that may restrict its visibility and then you will have the opportunity to fix issues before publishing your video. <Link to={""}>Learn more</Link></p>
                </Col>
                <Col sm={12}>
                  <div className='stepCard my-3'>
                    <div className='stepCardinfo'>
                      <div className='stepIcon'><FaRegCopyright/></div>
                      <div className='stepcardText'>
                        <b>Copyright</b>
                        <p>No issues found</p>
                      </div>
                    </div>
                    <div className='copyrightRemarks text-success'><IoMdCheckmark/></div>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className='stepCard my-3'>
                    <div className='stepCarpara'>
                    <p>Remember: These check results aren't final. Issues may come up in the future that impact your video. <Link to={""}>Learn more</Link></p>
                    </div>
                    <Link to={""} className='feedBackbtn'>Send Feedback</Link>
                  </div>
                </Col>
                </Row>
              </Col>
          </Row>
      </div>
    </>
  )
}
