import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";

export default function Pinvideo(props) {

    return (
        <>
            <div className="newestItem">
                <div className="row">
                    <div className="col-sm-5">
                        <div className="youtubeWrap">



                            <Link to={props.pinVideoLink} title={props.pinVideoTitle}>

                                <LazyLoadImage src={props.pinVideo}
                                    placeholderSrc={props.pinVideo}
                                    alt={props.pinVideoTitle}
                                    effect="blur"
                                    className="img-fluid"
                                    wrapperProps={{
                                        // If you need to, you can tweak the effect transition using the wrapper style.
                                        style: { transitionDelay: "1s" },
                                    }}

                                />


                            </Link>


                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="newItemInfo">
                            <Link to={"/" + props.pinVideoLink} className="newTitle">{props.pinVideoTitle}</Link>

                            <ul className="newInfo">
                                <li><FaEye /> {props.pinVideoViews} views</li>
                                <li><IoMdTime /> {props.pinVideoUploadTime} </li>
                            </ul>

                            <p>{props.pinVideoDesc}</p>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}