import React from 'react';
import '../vijaypath/vijaypath.css';
import {Link} from 'react-router-dom'

import {useParams} from 'react-router-dom';
import catbg from '../../images/catbg.jpg';
import Breadcumb from '../../components/breadcumb/Breadcumb';
import { Col, Row } from 'react-bootstrap';
import SectionTitle from '../../includes/sectionTitle/SectionTitle';

export default function Aboutvijaypath() {
    //const {catName} = useParams();

  return (
    <>
        <section className='catBanner' style={{backgroundImage: `url(${catbg})`}}>
          <div className='container-fluid'>
            <Row>
              <Col sm={12}><Breadcumb pageTitle={"Vijaypath"} /></Col>
            </Row>
          </div>
        </section>

        <section className='aboutvijaypath'>
            <div className='container'>
                <Row>
                    <Col sm={12}>
                        <SectionTitle stitle="About Vijay Path"/>

                        <p className='mb-4'>The aim of Support India Apps is to provide maximum
                              employment to the youth of India and there should be no
                              unemployment in India. Support India will provide all possible
                              assistance to the youth of India to generate maximum income
                              and fulfill their dreams.</p>


                        <div className='viajyItem'>
                            <b className='viajyItemTitle'>Vijay Path</b>
                            <p>That's why we are bringing Vijaypath Scheme,
                                this is our scheme Vijaypath, it will only provide direction to
                                the youth of India to fulfill their dreams.</p>
                            <p>In Vijaypath Scheme, maximum income can be generated
                                through online medium in very short time and with little hard
                                work.</p>
                        </div>

                        <p className='sectionBreak'><span>We are bringing the concept of <b>DANVEER</b> and <b>YOUDHA</b> in
                        Vijaypath Scheme</span></p>

                        <Row>
                              <Col sm={6}>
                                  <div className='viajyItem'>
                                     <Link to="/danveer"> <b className='viajyItemTitle'>Danveer</b></Link>
                                      <p>You can become a
                                          Danvier by donating 195 rupees in our organization. You can
                                          win good price money by sharing your video and collecting
                                          maximum likes.</p>
                                  </div>
                              </Col>

                              <Col sm={6}>
                                  <div className='viajyItem'>
                                  <Link to="/yodha"> <b className='viajyItemTitle'>Youdha</b></Link>
                                      <p>you will have to DANVIEER 20 people in your group.
                                          You will have to take membership of Rs 275 then ID generate
                                          after that you can become a youdha.</p>
                                  </div>
                              </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </section>
    </>
  )
}
