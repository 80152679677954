import React from 'react';
import { HiUpload } from "react-icons/hi";
import plusLight from '../../../images/plus-light.svg';
import { ImFilm } from "react-icons/im";
import uploadvideo from '../../../images/uploadvideobg.webp'


export default function UploadVideo() {
  return (
    <>
        {/* ====upload video sec start=== */}
        <section className="uploadvideo new_uploadvideo">
        <div className="texture_5 ">
            <img src={plusLight} alt="" title="" loading="lazy"/>
        </div>
        <div className="texture_6 ">
            <img src="assets/images/shape-12.svg" alt="" title="" loading="lazy"/>
        </div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="upload_wrapper" style={{background: `url(${uploadvideo})`}}>
                        <div className="upload_content">
                            <div className="uploadIcon"><ImFilm/></div>
                            <div className="uploadText">
                                <b>Upload Your Own Video</b>
                                <p>Write a detailed description. Include key points covered in the video, relevant links, and timestamps if needed.</p>
                            </div>
                        </div>
                        <button className="btn primary-btn" title="Play Now"><span><HiUpload /></span>Upload Now</button>
                    </div>
                </div>
            </div>
        </div>
     </section>
        {/* ====upload video sec close=== */}
    </>
  )
}
