import React from 'react';
import plusLight from '../../../images/plus-light.svg';
import advertisement1 from '../../../images/advertisement1.png'

export default function Addsection() {
  return (
    <>
            {/* =====adds section===== */}
     <div className="adsBar newsec">
        <div className="texture1">
        <img src={plusLight} alt="" title="" loading="lazy"/>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="adsWrapper">
                        <span className="adTag">Advertisement</span>
                        <div className="adsImg">
                            <img src={advertisement1} alt="ad1" loading="lazy" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
         </div>
     </div>
    
            {/* =====adds section===== */}

    </>
  )
}
