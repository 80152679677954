import React , { useState,useEffect } from 'react';
import { Col } from 'react-bootstrap';
import Videolist from '../../components/common/Videolisttemp3';
// import Trendingdata from '../../data/trending.json';

export default function Myvideos(props) {

  const catName = ['sports', 'education', 'kids','entertainment','news','reels'];

  const randomElement = catName[Math.floor(Math.random() * catName.length)];  

  const [trendingdata, setTrendingdata] = useState([]);

  const [trendingdatareel, setTrendingdatareel] = useState([]);
  
  const importData = async () => {
    import(`../../data/video-${randomElement}.json`)
      .then((res) => setTrendingdata(res.default))
      .catch(_ => null);
  };
  const importDataReel = async () => {
    import(`../../data/reels-${randomElement}.json`)
      .then((res) => setTrendingdatareel(res.default))
      .catch(_ => null);
  };
  
  useEffect( () => {
    importData();
    //importDataReel();
  }, [importData]);
  useEffect( () => {
    // importData();
    importDataReel();
  }, [importDataReel]);



  return (
    <>
         {
                      trendingdata.map((gridvalue)=>{
                        return(
                          <Videolist Img={gridvalue.Img} ImgAlt={gridvalue.ImgAlt} Title={gridvalue.Title} shortDetail={gridvalue.shortDetail} userImg={gridvalue.userImg} userImgTitle={gridvalue.userImgTitle} Channel={gridvalue.Channel} videoLink={"/watch/"+gridvalue.videoId} classValue="col-md-3" />
                        )
                      })
                    }
    </>
  )
}
