const Playlistdata = [
    {
        podcastThumb : "https://template.gentechtreedesign.com/html/streamlab/red-html/images/background/asset-77.jpg",
        episodeNo: 16,
        podcastName: "Business Mastery Series"
    },
    {
        podcastThumb : "https://template.gentechtreedesign.com/html/streamlab/red-html/images/background/asset-16.jpeg",
        episodeNo: 20,
        podcastName: "Streamlab is a long established fact"
    },
    {
        podcastThumb : "https://template.gentechtreedesign.com/html/streamlab/red-html/images/background/asset-77.jpg",
        episodeNo: 16,
        podcastName: "Business Mastery Series"
    },
    {
        podcastThumb : "https://template.gentechtreedesign.com/html/streamlab/red-html/images/background/asset-16.jpeg",
        episodeNo: 20,
        podcastName: "Streamlab is a long established fact"
    },
    {
        podcastThumb : "https://template.gentechtreedesign.com/html/streamlab/red-html/images/background/asset-77.jpg",
        episodeNo: 16,
        podcastName: "Business Mastery Series"
    },
    {
        podcastThumb : "https://template.gentechtreedesign.com/html/streamlab/red-html/images/background/asset-16.jpeg",
        episodeNo: 20,
        podcastName: "Streamlab is a long established fact"
    },
    {
        podcastThumb : "https://template.gentechtreedesign.com/html/streamlab/red-html/images/background/asset-77.jpg",
        episodeNo: 16,
        podcastName: "Business Mastery Series"
    },
    {
        podcastThumb : "https://template.gentechtreedesign.com/html/streamlab/red-html/images/background/asset-16.jpeg",
        episodeNo: 20,
        podcastName: "Streamlab is a long established fact"
    }
]

export default Playlistdata;