import { Col, Row } from 'react-bootstrap';
import thumb1 from '../../../images/recently/recent1.jpg'
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import CommentData from './CommentData';
import Commentcard from './Commentcard';

export default function Comments() {
    
    const [comment, setComment] = useState(CommentData);

    const handleCheckboxChange = (id) => {
      const updatedComment = comment.map(comment => {
        if (comment.id === id) {
          return { ...comment, checked: !comment.checked };
        }
        return comment;
      });
      setComment(updatedComment);
    };
  return (
    <>
        <>
            <Row>
                <Col sm={12}>
                <div class="table-responsive">
                      <table class="table commentTable">
                      {comment.map(commentValue => (
                        <Commentcard
                            commentData={commentValue}
                            key={commentValue.id}
                            checkId={commentValue.id}
                            videoThumb={commentValue.thumbImg}
                            videoTitle={commentValue.thumbTitle}
                            videoInfo={commentValue.thumbDescription}
                            viewersComment={commentValue.viewrsComment}
                            handleCheckboxChange={handleCheckboxChange}
                        />
                        ))}
                      </table>
                </div>
                </Col>
            </Row>
        </>
    </>
  )
}
