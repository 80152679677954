import { useState, useRef } from "react";
import uploadIcon from '../../images/MovieUploadIcon.png'
import UploadingPopup from './UploadingPopup';
// import FormPage from './FormPage'; // Import the new form page component
import './dragdrop.css';
import VideodetailsTab from "./videosteps/VideodetailsTab";

export default function Dragdropfile() {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState("");
  const [showVideoDetails, setShowVideoDetails] = useState(false); // State to control VideodetailsTab visibility

  // ref
  const inputRef = useRef(null);
  
  // handle drag events
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  // triggers when file is dropped
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };
  
  // triggers when file is selected with click
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };
  
  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  // handle files
  const handleFile = (files) => {
    // Display the uploading popup
    setIsUploading(true);
    setUploadProgress(0);
    setFileName(files[0].name);

    // Simulate file upload process with percentage increase
    const uploadInterval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(uploadInterval);
          setIsUploading(false);
          setShowVideoDetails(true); // Show the form page after upload is complete
          return 100;
        }
        return prevProgress + 10;
      });
    }, 200); // Increase progress by 10% every 200ms
  };
  
  const handleCloseVideoTab = () => {
    setShowVideoDetails(false);
  };
  // Conditional rendering based on the state
  if (showVideoDetails) {
    return <VideodetailsTab fileName={fileName} onClose={handleCloseVideoTab} />; // Pass handleCloseVideoTab as a prop
  }

  return (
    <>
      <section className="dragDropSec">
        <div className="container">
          <div className="row">
            <div sm={12}>
              <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                <input
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  multiple={true}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={dragActive ? "drag-active" : ""}
                >
                  <div>
                    <div className="dropIcon"><img src={uploadIcon}/></div>
                    <p>Drag and drop your file here or</p>
                    <button className="upload-button primary-btn" onClick={onButtonClick}>
                      Upload a file
                    </button>
                  </div>
                </label>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Use the UploadingPopup component */}
      {isUploading && <UploadingPopup progress={uploadProgress} fileName={fileName} />}
    </>
  );
}
