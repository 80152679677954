import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';

import ShortUniqueId from 'short-unique-id'

//import placeholder from '../../images/placeholder.svg'


export default function Reellist(props) {

    const uid = new ShortUniqueId({ length: 5 });
    
  return (
    <>
          <div className={props.classValue} key={uid.rnd()}>
              <div className="reelItem">
                  <Link to={props.videoId} className="reelImg" title={props.Title}>
                    
                      <LazyLoadImage  src={props.Img}
                placeholderSrc={props.Img}
                alt={props.ImgAlt}
                effect="blur"
                className="img-fluid"
                wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: { transitionDelay: "1s" },
                }}

            />


                  </Link>
                  <div className="reelOverley">
                      <div className="reelDetail">
                          <div className="reeluserInfo">
                              <Link className="reeluserimg" to={props.Channel} title={props.userImgTitle}>
                              
                              <LazyLoadImage title={props.userImgTitle} className="img-fluid"  src={props.userImg}
                placeholderSrc={props.userImg}
                alt={props.userImgAlt}
                effect="blur"
                wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: { transitionDelay: "1s" },
                }}

            />


                             
                              
                              
                              
                              </Link>
                              <Link to={props.videoId}  className="reelusername" title={props.Title}>{props.Title}</Link>
                          </div>
                          <p className="reelDetail">{props.shortDetail}</p>
                      </div>
                  </div>
              </div>
          </div>
    </>
  )
}

