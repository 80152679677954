import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'
import { Col, Row } from 'react-bootstrap';
import '../setting/settings.css'
import Account from './Account';
import Notification from './Notification';
import Downloads from './Downloads';
import Privacy from './Privacy';
import AdvanceSetting from './AdvanceSetting';

export default function Settings() {
  return (
    <>
        <div className='seetingsWrapper'>
                    <Tabs>
                    <Row>
                        <Col sm={12}>
                            <div className='yourHtab'>
                                <TabList>
                                    <Tab>Account</Tab>
                                    <Tab>Notification</Tab>
                                    <Tab>Downloads</Tab>
                                    <Tab>Privacy</Tab>
                                    <Tab>Advance Settings</Tab>
                                </TabList>
                            </div>

                        </Col>
                        <Col sm={12}>
                            <div class="yourtabbody">
                                <TabPanel>
                                    <Account/>
                                </TabPanel>
                                <TabPanel>
                                    <Notification/>
                                </TabPanel>
                                <TabPanel>
                                    <Downloads/>
                                </TabPanel>
                                <TabPanel>
                                    <Privacy/>
                                </TabPanel>
                                <TabPanel>
                                    <AdvanceSetting/>
                                </TabPanel>
                            </div>
                        </Col>
                    </Row>
                </Tabs>
        </div>
    </>
  )
}
