import React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Table from 'react-bootstrap/Table';
import 'react-tabs/style/react-tabs.css';
import { BiSolidVideos } from "react-icons/bi";
import { TbDeviceAnalytics } from "react-icons/tb";
import './yourvideos.css'
import thumb1 from '../../../images/recently/recent1.jpg'
import ChannelProfile from '../ChannelInfo';
import { LiaCommentSolid } from "react-icons/lia";
import { LuWallet } from "react-icons/lu";
import ContentDash from './ContentDash';
import Analytics from './Analytics';
import Comments from './Comments';
import Mywallet from '../../wallet/Mywallet';


export default function Yourvideos() {

    const [selectedOption, setSelectedOption] = useState('video');

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.id);
    };
  return (
    <>
        <section className='yourVideotab'>
        <Tabs>
            <Row>
            <Col sm={12}>
                <div className='yourHtab'>
                    <TabList>
                        <Tab><BiSolidVideos/> Content</Tab>
                        <Tab><TbDeviceAnalytics/> Analytics</Tab>
                        <Tab><LiaCommentSolid/> Comments</Tab>
                        <Tab><LuWallet/> Wallet</Tab>
                    </TabList>
                </div>
            </Col>
            <Col sm={12}>
            <div className='yourtabbody'>
                <TabPanel>
                    <ContentDash/>
                </TabPanel>
                <TabPanel>
                    <Analytics/>
                </TabPanel>
                <TabPanel>
                    <Comments/>
                </TabPanel>
                <TabPanel>
                    <Mywallet/>
                </TabPanel>
            </div>
            </Col>
                
            </Row>
        </Tabs>
        </section>
    </>
  )
}
