import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom';
import Tagsdata from '../../data/tags.json';

export default function Tags() {

    const options = {
        loop: false,
        margin: 10,
        // autoWidth:true,
        dots: false,
        navText: ['<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>', '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg>'],
        nav: true,
        responsive: {
            0: {
                items: 4.6,
                stagePadding: 15,
            },
            576: {
                items: 5.8,
                stagePadding: 15,
            },
            992: {
                items: 9.2
            },
            1200: {
                items: 10.5,
                stagePadding: 15,
            },
            1400: {
                items: 10.5
            }
        },

    };
    return (
        <>
            <OwlCarousel className='categoriesSlide owl-theme' {...options}>

                {
                    Tagsdata.map((tag, index) => {
                        return (<NavLink activeclassname="active" className={({ isActive }) => (isActive ? "categoriesItem active" : "categoriesItem")} to={tag.link.toLowerCase()} title={tag.name} key={tag.id}>{tag.name}</NavLink>)

                    })
                }



            </OwlCarousel>

        </>
    );
}
