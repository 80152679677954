import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import {Link} from 'react-router-dom'

import banner1 from '../../../images/herobanner/Watch-R-Banner.jpg';
import banner2 from '../../../images/herobanner/watch-R-banner-2.jpg';

import { IoIosAdd } from "react-icons/io";
import { SlControlPlay } from "react-icons/sl";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Banner() {

    const notify = (message) => toast(message);

    
    const options = {
            loop:true,
            margin:10,
            dots: true,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause:true,
            navText: ['<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>','<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg>'],
            nav:true,
            responsive:{
                    0:{
                        items:1,
                    },
                    576:{
                        items:1,
                    },
                    992:{
                        items:1
                    }
                    
                }
                
      };
  return (
    <>
        <main className="banner_sec">
            <OwlCarousel className='banner_slider owl-theme' {...options}>
              <div className="banner_item">
                <Link to="" className="bannerImg" style={{backgroundImage : 'url(' + banner1 + ')'}}></Link>
                <div className="hero_overley">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-8 col-lg-6">
                                <div className="overleyContent">
                                    <h1 className="filmName">Golden voice of India</h1>

                                    <ul>
                                        <li>Music Reality Show</li>
                                        <li><div className="parental-guidance">13+</div></li>
                                        <li>1hr 30 mins</li>
                                    </ul>
                                    <div className="bannerBtns">
                                        <button className="btn primary-btn" title="Play Now"><span><SlControlPlay /></span>Subscribe to Watch</button>

                                        <button className="btn primary-btn bannerbtn2 addbtn" title="Add Watchlist"  data-toggle="tooltip" data-placement="top" onClick={() => notify("Please Login to Add")}><span><IoIosAdd/></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="banner_item">
                <Link to="" className="bannerImg" style={{backgroundImage : 'url(' + banner2 + ')'}}></Link>
                <div className="hero_overley">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-8 col-lg-6">
                                <div className="overleyContent">
                                    <h1 className="filmName">Dancing Star</h1>

                                    <ul>
                                        <li>Dance Reality Show</li>
                                        <li><div className="parental-guidance">13+</div></li>
                                        <li>1hr 30 mins</li>
                                    </ul>
                                    <div className="bannerBtns">
                                        <button className="btn primary-btn" title="Play Now"><span><SlControlPlay /></span>Subscribe to Watch</button>

                                        <button className="btn primary-btn bannerbtn2 addbtn" title="Add Watchlist"  data-toggle="tooltip" data-placement="top"><span><IoIosAdd/></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="banner_item">
                <Link to="" className="bannerImg" style={{backgroundImage : 'url(' + banner1 + ')'}}>
                </Link>
                <div className="hero_overley">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-8 col-lg-6">
                                <div className="overleyContent">
                                    <h1 className="filmName">Golden voice of India</h1>

                                    <ul>
                                        <li>Music Reality Show</li>
                                        <li><div className="parental-guidance">13+</div></li>
                                        <li>1hr 30 mins</li>
                                    </ul>
                                    <div className="bannerBtns">
                                        <button className="btn primary-btn" title="Play Now"><span><SlControlPlay /></span>Subscribe to Watch</button>

                                        <button className="btn primary-btn bannerbtn2 addbtn" title="Add Watchlist"  data-toggle="tooltip" data-placement="top"><span><IoIosAdd/></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="banner_item">
                <Link to="" className="bannerImg" style={{backgroundImage : 'url(' + banner2 + ')'}}>
                </Link>
                <div className="hero_overley">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-8 col-lg-6">
                                <div className="overleyContent">
                                    <h1 className="filmName">Dancing Star</h1>

                                    <ul>
                                        <li>Dance Reality Show</li>
                                        <li><div className="parental-guidance">13+</div></li>
                                        <li>1hr 30 mins</li>
                                    </ul>
                                    <div className="bannerBtns">
                                        <button className="btn primary-btn" title="Play Now"><span><SlControlPlay /></span>Subscribe to Watch</button>

                                        <button className="btn primary-btn bannerbtn2 addbtn" title="Add Watchlist"  data-toggle="tooltip" data-placement="top"><span><IoIosAdd/></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                        
                    </OwlCarousel>
        </main>
    </>
  )
}
