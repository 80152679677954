import React from 'react';
import { useState,useEffect } from 'react';
import catbg from '../../images/catbg.jpg';
import '../categoriepage/categories.css'
import Breadcumb from '../../components/breadcumb/Breadcumb';
import { Col, Row } from 'react-bootstrap';


import Videos from '../../components/common/Reellist';
import Reels from '../../components/common/Reellist';
//import Trendingdata from '../../data/trending.json';
//import Trendingdatareel from '../../data/trandingreel.json';




import { FaList } from "react-icons/fa";
import { IoGrid } from "react-icons/io5";

import {useParams} from 'react-router-dom';

export default function Hastagpage() {

  const {tagName} = useParams();

  const catName = ['sports', 'education', 'kids','entertainment','news','reels'];

  const randomElement = catName[Math.floor(Math.random() * catName.length)];  

  const [trendingdata, setTrendingdata] = useState([]);

  const [trendingdatareel, setTrendingdatareel] = useState([]);
  
  const importData = async () => {
    import(`../../data/video-${randomElement}.json`)
      .then((res) => setTrendingdata(res.default))
      .catch(_ => null);
  };
  const importDataReel = async () => {
    import(`../../data/reels-${randomElement}.json`)
      .then((res) => setTrendingdatareel(res.default))
      .catch(_ => null);
  };
  
  useEffect( () => {
    importData();
    //importDataReel();
  }, [importData]);
  useEffect( () => {
    // importData();
    importDataReel();
  }, [importDataReel]);





  

  const [view, setView] = useState('grid');

  const handleListViewClick = () => {
    setView('list');
  };

  const handleGridViewClick = () => {
    setView('grid');
  };


  return (
    <>
        <section className='catBanner' style={{backgroundImage: `url(${catbg})`}}>
          <div className='container-fluid'>
            <Row>
              <Col sm={12}><Breadcumb pageTitle={"#"+tagName} /></Col>
            </Row>
          </div>
        </section>

        <section className='catListing'>
          <div className='container-fluid'>
            <Row>
              <Col sm={12}>
                <div className='catFilter'>
                  <span>Filter by:</span>
                  <div className={`filterBtn ${view === 'list' ? 'active' : ''}`} id='listBTn' onClick={handleListViewClick}><FaList/>Videos</div>
                  <div className={`filterBtn ${view === 'grid' ? 'active' : ''}`} id='gridBtn' onClick={handleGridViewClick}><IoGrid/>Reels</div>
                </div>
              </Col>
            </Row>
            {view === 'grid' && (
              <div className='gridView'>
               

             


          
          <Row>
                {
                      trendingdatareel.map((reels)=>{
                        return(
                          <Reels Img={reels.videoImage} ImgAlt={reels.videotitle} Title={reels.videotitle} shortDetail={reels.videoDesc} userImg={reels.ChannelImage} userImgTitle={reels.ChanelTitle} Channel={reels.ChanelUrl} videoId={"/reel/"+reels.videoId} classValue="col-sm-6 col-lg-2 col-xl-2" />
                        )
                      })
                    }
                </Row>
          
        
               




                {/* <Row>
                  <Col sm={12} className='text-center'>
                    <button className="btn primary-btn" title="view all" onClick={fetchData}>Show More</button>
                  </Col>
                </Row> */}
            </div>
            
            )}

            {view === 'list' && (
              <div className='listView'>
                <Row>
                {
                      trendingdata.map((videos)=>{
                      return(
                        <Videos  Img={videos.Img} ImgAlt={videos.ImgAlt} Title={videos.Title} shortDetail={videos.shortDetail} userImg={videos.userImg} userImgTitle={videos.userImgTitle} Channel={videos.Channel} videoId={"/watch/"+videos.videoId} classValue="col-sm-6 col-lg-3 col-xl-3" />
                      )
                      })
                    }
                </Row>
                {/* <Row>
                  <Col sm={12} className='text-center'>
                    <button className="btn primary-btn" title="view all">Show More</button>
                  </Col>
                </Row> */}
              </div>
            )}

          </div>
        </section>
    </>
  )
}
