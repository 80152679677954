import React from 'react';

import {Link} from 'react-router-dom'


import catbg from '../../images/catbg.jpg';
import Breadcumb from '../../components/breadcumb/Breadcumb';
import { Col, Row } from 'react-bootstrap';
import SectionTitle from '../../includes/sectionTitle/SectionTitle';

export default function Contact() {
    //const {catName} = useParams();

  return (
    <>
        <section className='catBanner' style={{backgroundImage: `url(${catbg})`}}>
          <div className='container-fluid'>
            <Row>
              <Col sm={12}><Breadcumb pageTitle={"Contact"} /></Col>
            </Row>
          </div>
        </section>

        <section className='aboutvijaypath'>
            <div className='container'>
                <Row>
                    <Col sm={12}>
                        <SectionTitle stitle="Contact Us"/>

                        <p className='mb-4'>Please mail us on admin@supportindiaapp.com for account deletion (mention email/phone) and other query.</p>

                        
                    </Col>
                </Row>
            </div>
        </section>
    </>
  )
}
