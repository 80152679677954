import React from 'react'
import Dragdropfile from './Dragdropfile'

export default function UploadVideo() {
  return (
    <div>
      <Dragdropfile/>
    </div>
  )
}
