import React from 'react'
import Banner from './banner/Banner';
import "../home/home.css"
import Addsection from './addsSection/Addsection';
import Trendingreel from './trendingReel/Trendingreel';
import Education from './education/Education';
import Mostwatched from './mostwatched/Mostwatched';
import Recentlyadded from './recentlyadded/Recentlyadded';
import Top10 from './topTen/Top10';
import UploadVideo from './uploadVideo/UploadVideo';






export default function Home() {
  return (
    <>
         <Banner/>
         <Addsection/>
         <Trendingreel/>
         <Education/>
         <Mostwatched/>
         <Recentlyadded/>
         <Top10/>
         <UploadVideo/>
    </>
  )
}
