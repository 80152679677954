import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
// import './Switch.css';

export default function NotificationSwitch(props) {
    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => {
      setIsChecked(!isChecked);
    };
  return (
    <>
            <div className={`switch-Item ${isChecked ? 'checked' : ''}`}>
                <div className='switchInfo'>
                    <b>{props.switchTitle}</b>
                    <small>{props.switchPara}</small>
                </div>
                <div className='switchwrap'>
                {/* <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleToggle}
                    className="switch-checkbox"
                    id="switch"
                />
                <label className="switch-label" htmlFor="switch">
                    <span className="switch-button" />
                </label> */}

                <Form>
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label=""
                        onChange={handleToggle}
                        checked={isChecked}
                    />
                </Form>
                </div>
            </div>
    </>
  )
}
