import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import 'react-loading-skeleton/dist/skeleton.css'



import SectionTitle from '../../../includes/sectionTitle/SectionTitle';
import dots from '../../../images/dot1.png'

//import Trendingdata from '../../../data/trandingreel.json';
import Reellist from '../../../components/common/Reellist';



export default function Trendingreel() {

    const catName = ['sports', 'education', 'kids','entertainment','news','reels'];
    const randomElement = catName[Math.floor(Math.random() * catName.length)];  
    const [Trendingdata, setTrendingdata] = useState([]);

    const importData = async () => {
        import(`../../../data/reels-${randomElement}.json`)
          .then((res) => setTrendingdata(res.default))
          .catch(_ => null);
      };

      useEffect( () => {
        importData();
        //importDataReel();
      }, [importData]);
      
      
    const secStyle = {
        backgroundImage: `url(${dots})`
    }
    const options = {
        loop: false,
        margin: 10,
        navText: ['<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>', '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path></svg>'],
        nav: true,
        responsive: {
            0: {
                items: 1.6,
                stagePadding: 15,
            },
            576: {
                items: 3.2
            },
            992: {
                items: 6
            },
            1200: {
                items: 6
            }
        }

    };

    return (
        <>
            {/* =====trending reels section start====== */}
            <div className="trendingReels new_trending" style={secStyle}>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <SectionTitle stitle="Trending Reels" />
                        </div>
                        <div className="col-sm-12">
                            <OwlCarousel className='trendingSlider owl-theme' {...options}>

                                {
                                    Trendingdata.map((reels) => {
                                        return (
                                            <Reellist Img={reels.videoImage} ImgAlt={reels.videotitle} Title={reels.videotitle} shortDetail={reels.videoDesc} userImg={reels.ChannelImage} userImgTitle={reels.ChanelTitle} Channel={reels.ChanelUrl} videoId={"/reel/"+reels.videoId} classValue="col-12" />
                                        )
                                    })
                                }


                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
            {/* =====trending reels section close====== */}

        </>
    )
}
