import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
// import RadioButton from './RadioButton';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import wicon1 from '../../images/wIcon1.svg';
import wicon2 from '../../images/wIcon2.svg';
import wicon3 from '../../images/wIcon3.svg';

import { IoIosArrowForward } from "react-icons/io";
// import FilteRadio from './FilteRadio';
import FilterRadio from './FilterRadio';
import Coupon from '../../components/coupons/Coupon';

export default function Mywallet() {

    const radioOptions = [
        { label: 'All', value: 'All' },
        { label: 'Credit', value: 'Credit' },
        { label: 'Debit', value: 'Debit' }
      ];

  return (
    <>
        <h1 className="subscribe_heading">My Wallet</h1>
        <div className="totalBalance">
            <div className="balanceTitle">
                <b>Total Balance</b>
                <small>(Wallet Balance)</small>
            </div>
            <div className="priceAmount">
                ₹<span>100.00</span>
            </div>
        </div>
        <div className='walletTab'>
            <Tabs>
                <TabList>
                    <Tab>Balance Info</Tab>
                    <Tab>Withdrawal Amount</Tab>
                    <Tab>Withdrawal History</Tab>
                    <Tab>Passbook</Tab>
                </TabList>

                <TabPanel>
                    <Row>
                        <Col sm={6} xl={3}>
                              <div className="balanceItem">
                                  <div className="balanceIcon">
                                      <img src={wicon1} alt='icon1' className='img-fluid'/>
                                  </div>
                                  <b>₹<span>100.00</span></b>
                                  <small>Current Balace</small>
                              </div>
                        </Col>
                        <Col sm={6} xl={3}>
                              <div className="balanceItem">
                                  <div className="balanceIcon">
                                      <img src={wicon2} alt='icon1' className='img-fluid'/>
                                  </div>
                                  <b>₹<span>40.00</span></b>
                                  <small>Last withdrawal</small>
                              </div>
                        </Col>
                        <Col sm={6} xl={3}>
                              <div className="balanceItem">
                                  <div className="balanceIcon">
                                      <img src={wicon3} alt='icon1' className='img-fluid'/>
                                  </div>
                                  <b>₹<span>40.00</span></b>
                                  <small>Last Deduction</small>
                              </div>
                        </Col>
                    </Row>
                </TabPanel>
                <TabPanel>
                    <form>
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-lg-4"><span style={{ color: "#ddd" }}>Enter Your withdrawal Amount: </span></div>
                            <div className="col-sm-12 col-lg-8">
                                <div className="form_check">
                                    <input type="text" placeholder="Enter Amount" className="form-control" />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="methodWrapperForm">
                                    <b>Enter Account Details: </b>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-sm-12 col-lg-4"><span style={{ color: "#ddd" }}>Your Bank Name: </span></div>
                                        <div className="col-sm-12 col-lg-8">
                                            <div className="form_check">
                                                <input type="text" placeholder="Type Bank Name" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-sm-12 col-lg-4"><span style={{ color: "#ddd" }}>Your Account No: </span></div>
                                        <div className="col-sm-12 col-lg-8">
                                            <div className="form_check">
                                                <input type="text" placeholder="Enter Account No" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-sm-12 col-lg-4"><span style={{ color: "#ddd" }}>IFSC Code: </span></div>
                                        <div className="col-sm-12 col-lg-8">
                                            <div className="form_check">
                                                <input type="text" placeholder="Enter Ifsc Code" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 text-center">
                                            <button className="btn primary-btn" title="Play Now">Next <IoIosArrowForward /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </TabPanel>
                <TabPanel>
                    <Row>
                          <div className="col-sm-12">
                              <div className="rechargeHead">
                                  <p>Showing last 6 withdrawal</p>

                                  <div className="resulbtns">
                                      <div className="resultNav">previous</div>
                                      <div className="resultNav">Next</div>
                                  </div>
                              </div>
                          </div>

                          <div className="col-sm-6 col-lg-4">
                                            <div className="rechargeItem">
                                                <div className="rechargeInfo">Txn Reference : <span>3770xxxx0055</span></div>
                                                <div className="rechargeInfo">Amount : <span>₹ 100</span></div>
                                                <div className="rechargeInfo">Txn Time : <span>Jan 10, 2024 11:57:05</span></div>
                                                <div className="d-flex justify-content-between flex-wrap">
                                                    <div className="rechargeInfo">Bank : <span>PayTm Ag</span></div>
                                                    <div className="rechargeInfo">Bank Ref ID : <span>02364857545545551</span></div>
                                                </div>
                                                <div className="rechargeInfo">Status : <span>Withdrawal Successful</span></div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4">
                                            <div className="rechargeItem">
                                                <div className="rechargeInfo">Txn Reference : <span>3770xxxx0055</span></div>
                                                <div className="rechargeInfo">Amount : <span>₹ 100</span></div>
                                                <div className="rechargeInfo">Txn Time : <span>Jan 10, 2024 11:57:05</span></div>
                                                <div className="d-flex justify-content-between flex-wrap">
                                                    <div className="rechargeInfo">Bank : <span>PayTm Ag</span></div>
                                                    <div className="rechargeInfo">Bank Ref ID : <span>02364857545545551</span></div>
                                                </div>
                                                <div className="rechargeInfo">Status : <span>Withdrawal Successful</span></div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4">
                                            <div className="rechargeItem">
                                                <div className="rechargeInfo">Txn Reference : <span>3770xxxx0055</span></div>
                                                <div className="rechargeInfo">Amount : <span>₹ 100</span></div>
                                                <div className="rechargeInfo">Txn Time : <span>Jan 10, 2024 11:57:05</span></div>
                                                <div className="d-flex justify-content-between flex-wrap">
                                                    <div className="rechargeInfo">Bank : <span>PayTm Ag</span></div>
                                                    <div className="rechargeInfo">Bank Ref ID : <span>02364857545545551</span></div>
                                                </div>
                                                <div className="rechargeInfo">Status : <span>Withdrawal Successful</span></div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4">
                                            <div className="rechargeItem">
                                                <div className="rechargeInfo">Txn Reference : <span>3770xxxx0055</span></div>
                                                <div className="rechargeInfo">Amount : <span>₹ 100</span></div>
                                                <div className="rechargeInfo">Txn Time : <span>Jan 10, 2024 11:57:05</span></div>
                                                <div className="d-flex justify-content-between flex-wrap">
                                                    <div className="rechargeInfo">Bank : <span>PayTm Ag</span></div>
                                                    <div className="rechargeInfo">Bank Ref ID : <span>02364857545545551</span></div>
                                                </div>
                                                <div className="rechargeInfo">Status : <span>Withdrawal Successful</span></div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4">
                                            <div className="rechargeItem">
                                                <div className="rechargeInfo">Txn Reference : <span>3770xxxx0055</span></div>
                                                <div className="rechargeInfo">Amount : <span>₹ 100</span></div>
                                                <div className="rechargeInfo">Txn Time : <span>Jan 10, 2024 11:57:05</span></div>
                                                <div className="d-flex justify-content-between flex-wrap">
                                                    <div className="rechargeInfo">Bank : <span>PayTm Ag</span></div>
                                                    <div className="rechargeInfo">Bank Ref ID : <span>02364857545545551</span></div>
                                                </div>
                                                <div className="rechargeInfo">Status : <span>Withdrawal Successful</span></div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4">
                                            <div className="rechargeItem">
                                                <div className="rechargeInfo">Txn Reference : <span>3770xxxx0055</span></div>
                                                <div className="rechargeInfo">Amount : <span>₹ 100</span></div>
                                                <div className="rechargeInfo">Txn Time : <span>Jan 10, 2024 11:57:05</span></div>
                                                <div className="d-flex justify-content-between flex-wrap">
                                                    <div className="rechargeInfo">Bank : <span>PayTm Ag</span></div>
                                                    <div className="rechargeInfo">Bank Ref ID : <span>02364857545545551</span></div>
                                                </div>
                                                <div className="rechargeInfo">Status : <span>Withdrawal Successful</span></div>
                                            </div>
                                        </div>
                    </Row>
                </TabPanel>
                <TabPanel>
                    <Row>
                          <div className='col-sm-12'>
                            <div className="rechargeHead">
                                <p>Filters</p>

                                  <div className="methodWrapper">
                                    <FilterRadio options={radioOptions} name="myRadioGroup"/>
                                  </div>
                          </div>
                          </div>

                          <div className="col-sm-6 col-lg-4">
                              <div className="rechargeItem">
                                  <div className="rechargeTag debitItem">Debit</div>
                                  <div className="rechargeInfo">Txn Reference : <span>3770xxxx0055</span></div>
                                  <div className="rechargeInfo">Amount : <span>₹ 100</span></div>
                                  <div className="rechargeInfo">Txn Time : <span>Jan 10, 2024 11:57:05</span></div>
                                  <div className="d-flex justify-content-between flex-wrap">
                                      <div className="rechargeInfo">Bank : <span>PayTm Ag</span></div>
                                      <div className="rechargeInfo">Bank Ref ID : <span>02364857545545551</span></div>
                                  </div>
                                  <div className="rechargeInfo">Status : <span>Withdrawal Successful</span></div>
                              </div>
                          </div>

                          <div className="col-sm-6 col-lg-4">
                              <div className="rechargeItem">
                                  <div className="rechargeTag creditItem">Credit</div>
                                  <div className="rechargeInfo">Txn Reference : <span>3770xxxx0055</span></div>
                                  <div className="rechargeInfo">Amount : <span>₹ 100</span></div>
                                  <div className="rechargeInfo">Txn Time : <span>Jan 10, 2024 11:57:05</span></div>
                                  <div className="d-flex justify-content-between flex-wrap">
                                      <div className="rechargeInfo">Bank : <span>PayTm Ag</span></div>
                                      <div className="rechargeInfo">Bank Ref ID : <span>02364857545545551</span></div>
                                  </div>
                                  <div className="rechargeInfo">Status : <span>Withdrawal Successful</span></div>
                              </div>
                          </div>

                          <div className="col-sm-6 col-lg-4">
                              <div className="rechargeItem">
                                  <div className="rechargeTag creditItem">Credit</div>
                                  <div className="rechargeInfo">Txn Reference : <span>3770xxxx0055</span></div>
                                  <div className="rechargeInfo">Amount : <span>₹ 100</span></div>
                                  <div className="rechargeInfo">Txn Time : <span>Jan 10, 2024 11:57:05</span></div>
                                  <div className="d-flex justify-content-between flex-wrap">
                                      <div className="rechargeInfo">Bank : <span>PayTm Ag</span></div>
                                      <div className="rechargeInfo">Bank Ref ID : <span>02364857545545551</span></div>
                                  </div>
                                  <div className="rechargeInfo">Status : <span>Withdrawal Successful</span></div>
                              </div>
                          </div>

                          <div className="col-sm-6 col-lg-4">
                              <div className="rechargeItem">
                                  <div className="rechargeTag debitItem">Debit</div>
                                  <div className="rechargeInfo">Txn Reference : <span>3770xxxx0055</span></div>
                                  <div className="rechargeInfo">Amount : <span>₹ 100</span></div>
                                  <div className="rechargeInfo">Txn Time : <span>Jan 10, 2024 11:57:05</span></div>
                                  <div className="d-flex justify-content-between flex-wrap">
                                      <div className="rechargeInfo">Bank : <span>PayTm Ag</span></div>
                                      <div className="rechargeInfo">Bank Ref ID : <span>02364857545545551</span></div>
                                  </div>
                                  <div className="rechargeInfo">Status : <span>Withdrawal Successful</span></div>
                              </div>
                          </div>

                          <div className="col-sm-6 col-lg-4">
                              <div className="rechargeItem">
                                  <div className="rechargeTag debitItem">Debit</div>
                                  <div className="rechargeInfo">Txn Reference : <span>3770xxxx0055</span></div>
                                  <div className="rechargeInfo">Amount : <span>₹ 100</span></div>
                                  <div className="rechargeInfo">Txn Time : <span>Jan 10, 2024 11:57:05</span></div>
                                  <div className="d-flex justify-content-between flex-wrap">
                                      <div className="rechargeInfo">Bank : <span>PayTm Ag</span></div>
                                      <div className="rechargeInfo">Bank Ref ID : <span>02364857545545551</span></div>
                                  </div>
                                  <div className="rechargeInfo">Status : <span>Withdrawal Successful</span></div>
                              </div>
                          </div>

                          <div className="col-sm-6 col-lg-4">
                              <div className="rechargeItem">
                                  <div className="rechargeTag creditItem">Credit</div>
                                  <div className="rechargeInfo">Txn Reference : <span>3770xxxx0055</span></div>
                                  <div className="rechargeInfo">Amount : <span>₹ 100</span></div>
                                  <div className="rechargeInfo">Txn Time : <span>Jan 10, 2024 11:57:05</span></div>
                                  <div className="d-flex justify-content-between flex-wrap">
                                      <div className="rechargeInfo">Bank : <span>PayTm Ag</span></div>
                                      <div className="rechargeInfo">Bank Ref ID : <span>02364857545545551</span></div>
                                  </div>
                                  <div className="rechargeInfo">Status : <span>Withdrawal Successful</span></div>
                              </div>
                          </div>
                    </Row>

                </TabPanel>
            </Tabs>
        </div>

        <Coupon/>
    </>
  )
}
