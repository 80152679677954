import React from 'react';
import uploadLoader from '../../images/Movie-Upload-Icon.gif'

export default function UploadingPopup({ progress, fileName }) {
    return (
        <>
            <div className="uploading-popup">
                <div className="progress-bar">
                    <div className="progress" style={{ width: `${progress}%` }}>
                        {progress}%
                    </div>
                </div>
                <div className='uploadIcon'><img src={uploadLoader}/> </div>
                <p>Uploading... {progress}%</p>

                <span>File Name: <strong>{fileName}</strong></span>
            </div>
            <div className='uploading-popupBG'></div>
        </>
    )
}

