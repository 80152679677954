import React, { useState } from 'react';

export default function FilterRadio({options, name}) {
    const [selectedOption, setSelectedOption] = useState(options[0].value);

    const handleChange = (event) => {
      setSelectedOption(event.target.value);
    };
  return (
    <>
      {options.map((option, index) => (
        <div key={index} className='form-check'>
          <input 
            type="radio" 
            id={option.value} 
            name={name} 
            value={option.value} 
            checked={selectedOption === option.value} 
            onChange={handleChange} 
            className='form-check-input'
          />
          <label htmlFor={option.value} className='form-check-label'>{option.label}</label>
        </div>
      ))}
      {/* <p>Selected Option: {selectedOption}</p> */}
    </>
  )
}
