import React from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import { SlControlPlay } from "react-icons/sl";
import { RiShareForwardLine } from "react-icons/ri";
import ShortUniqueId from 'short-unique-id'


export default function top10temp(props) {
    const uid = new ShortUniqueId({ length: 5 });
  return (
    <>
          
        
<div className="topItem" key={uid.rnd()}>
                                <div className="numberCounter">{props.Order}</div>
                                <div className="topContent">
                                <Link to={props.videoLink} className="topImg" title={props.Title}>
                    
                    <LazyLoadImage  src={props.Img}
              placeholderSrc={props.Img}
              alt={props.ImgAlt}
              className="img-fluid"
              effect="blur"
              wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
              }}

          />


                </Link>
                                    <div className="topOverley">
                                    <Link to={props.videoLink}  title={props.Title}>{props.Title}</Link>
                                        <div className="btngrp">
                                            <div className="btnItem">
                                            <Link to={props.videoLink}  title={props.Title}> <SlControlPlay/>
                                                Watch Now </Link>
                                            </div>
                                            <div className="btnItem btn2">
                                                <RiShareForwardLine/>
                                                Share
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>

    </>
  )
}








