import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import { TbDownload } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import {Link} from 'react-router-dom'

export default function Downloads() {

  const [download, setdownload] = useState("eachtime");

  function onChangeValue(event) {
    setdownload(event.target.value);
  }

  return (
    <>
        <div className='downloadWrap'>
        <Row>
            <Col sm={12}>
                <div class="contentFilterwrapper"><span>Control your downloads settings</span></div>
                <p className='signPara'>Downloads settings apply to this browser only</p>
            </Col>
        </Row>
        <Row>
          <Col sm={6} lg={4} xl={3}>
            <div className='downloadcard' onChange={onChangeValue}>
              <span className='downloadIcon'><TbDownload/></span>
              <b>Download quality</b>
              <div className={`radioItem ${download === "eachtime"? 'checked': ''}`}>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" value="eachtime" id="eachtime" checked={download === "eachtime"} />
                  <label class="form-check-label" for="eachtime">
                  Ask each time
                  </label>
                </div>
              </div>
              <div className={`radioItem ${download === "standered"? 'checked': ''}`}>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" value="standered" id="standered" checked={download === "standered"} />
                  <label class="form-check-label" for="standered">
                  Standard (480p)
                  </label>
                </div>
              </div>
              <div className={`radioItem ${download === "low"? 'checked': ''}`}>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" value="low" id="low"  checked={download === "low"}/>
                  <label class="form-check-label" for="low">
                  Low (144p)
                  </label>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={6} lg={4} xl={3}>
            <div className='downloadcard'>
              <span className='downloadIcon'><RiDeleteBinLine/></span>
              <b>Delete all downloads</b>
              <small>Deleting downloads will free space on this device</small>

              <Link class="btn primary-btn" title="Play Now" to="/">Delete all downloads</Link>
            </div>
          </Col>
        </Row>
        </div>
    </>
  )
}
