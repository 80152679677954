import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

import './steps.css'
import { Col } from 'react-bootstrap';

import { IoClose } from "react-icons/io5";
import { SiTicktick } from "react-icons/si";

// Array of step names
const steps = ['Details', 'Video Elements', 'Checks', 'Visibility'];

export default function VideodetailsTab({ fileName, onClose }) {
    const [currentStep, setCurrentStep] = useState(0);

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleFinish = () => {
        alert('Form submitted!');
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return <Step1 uploadfile={fileName} />;
            case 1:
                return <Step2 uploadfile={fileName}/>;
            case 2:
                return <Step3 uploadfile={fileName} />;
            case 3:
                return <Step4 uploadfile={fileName} />;
            default:
                return <Step1 uploadfile={fileName}/>;
        }
    };

    return (
        <>
            <section className='stepSec'>
                <div className="container">
                    <div className='stepsWrapper'>
                        <div className='row'>
                            <Col sm={12}>
                                <div className='stepHeader'>
                                    <h2 className="">{fileName}</h2>
                                    <button className='closeVideoTab' onClick={onClose}><IoClose /></button>
                                </div>

                                <StepBar currentStep={currentStep} />

                                <div className="Step">
                                    <div className='stepOuter'>
                                        <div className='stepLevel'>{steps[currentStep]}</div> {/* Show active page name */}
                                        {renderStepContent()}
                                    </div>
                                    
                                    <div className='stepFooter'>
                                        <div className="stepFootwrap">
                                            <div className='stepInfo'>
                                                <p><SiTicktick/>  <span>Processing upto HD</span> </p>
                                            </div>
                                            <div className='btnGrp'>
                                            <button
                                                className="btn back-Btn"
                                                onClick={handleBack}
                                                disabled={currentStep === 0}
                                            >
                                                Back
                                            </button>
                                            {currentStep < steps.length - 1 ? (
                                                <button className="btn primary-btn" onClick={handleNext}>
                                                    Next
                                                </button>
                                            ) : (
                                                <button className="btn primary-btn btn-success" onClick={handleFinish}>
                                                    Save
                                                </button>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


function StepBar({ currentStep }) {
    return (
        <div className="step-bar">
            {steps.map((step, index) => (
                <div
                    key={index}
                    className={`step-item ${index <= currentStep ? 'active' : ''}`}
                >
                    <div className="step-number">{index + 1}</div>
                    <div className="step-label">{step}</div>
                    {index < steps.length - 1 && (
                        <div className={`step-line ${index < currentStep ? 'completed' : ''}`}></div>
                    )}
                </div>
            ))}
        </div>
    );
}
