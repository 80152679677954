import React from 'react';
import { LuBellRing } from "react-icons/lu";
import { PiVideoDuotone } from "react-icons/pi";

export default function ChannelInfo() {
  return (
    <>
        <div className='channelProfile'>
            <div className='vchannelDp'>
                <img src='https://cdn.pixabay.com/photo/2024/07/22/06/53/woman-8911962_1280.jpg' alt='' className='img-fluid'/>
            </div>
            <div className='channelProfileInfo text-center'>
                <b>Your Channel</b>
                <p className='VchannelName'>Happy Vlogging</p>

                <div className='profileqInfo'>
                    <span><LuBellRing /></span>
                    <div className='profileqData'>
                        <small>Subscribers</small>
                        <b>2.4 K</b>
                    </div>
                </div>
                <div className='profileqInfo'>
                    <span><PiVideoDuotone /></span>
                    <div className='profileqData'>
                        <small>Videos</small>
                        <b>110</b>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
