import React from 'react';
import { PiSubtitles } from "react-icons/pi";
import { LuImagePlus } from "react-icons/lu";
import { AiOutlineInfoCircle } from "react-icons/ai";

export default function Step2() {
  return (
    <>
      <div className='stepWrap'>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='stepCard'>
            <div className='stepCardinfo'>
              <div className='stepIcon'><PiSubtitles/></div>
              <div className='stepcardText'>
                <b>Add Subtitles</b>
                <p>Reach a broader audience by adding it.</p>
              </div>
            </div>
            <button className='ripple addBtn'>Add</button>
          </div>
        </div>
        <div className='col-sm-12'>
          <div className='stepCard'>
            <div className='stepCardinfo'>
              <div className='stepIcon'><LuImagePlus/></div>
              <div className='stepcardText'>
                <b>Add an End Screen</b>
                <p>Promote related content at the end of the video</p>
              </div>
            </div>
            <button className='ripple addBtn'>Add</button>
          </div>
        </div>
        <div className='col-sm-12'>
          <div className='stepCard'>
            <div className='stepCardinfo'>
              <div className='stepIcon'><AiOutlineInfoCircle/></div>
              <div className='stepcardText'>
                <b>Add Cards</b>
                <p>Promote related content during your video</p>
              </div>
            </div>
            <button className='ripple addBtn'>Add</button>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}
