import React from 'react';
import '../vijaypath/vijaypath.css';

import {Link, useParams} from 'react-router-dom';
import catbg from '../../images/catbg.jpg';
import Breadcumb from '../../components/breadcumb/Breadcumb';
import { Col, Row } from 'react-bootstrap';
import SectionTitle from '../../includes/sectionTitle/SectionTitle';
import { AiFillLike } from "react-icons/ai";

export default function Danveer() {
   // const {catName} = useParams();
  return (
    <>
        <section className='catBanner' style={{backgroundImage: `url(${catbg})`}}>
          <div className='container-fluid'>
            <Row>
              <Col sm={12}><Breadcumb pageTitle={"Danveer"} /></Col>
            </Row>
          </div>
        </section>

        <section className='danveerSec'>
            <div className='container'>
              <Row>
                    <Col sm={12}>
                        <SectionTitle stitle="Danveer"/>
                    </Col>
              </Row>
              <Row>
                    <Col sm={6} lg={3}>
                        <div className='danveerItem'>
                            <div className='dIcon'>
                              <img width="64" height="64" src="https://img.icons8.com/dotty/64/ff5722/donate.png" alt="donate"/>
                            </div>
                            <div className='dInfo'>
                              <p>You have to donate <b>Rs.195/-</b> to Transmitto development foundation to
                              after that you become a donor</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} lg={3}>
                        <div className='danveerItem'>
                            <div className='dIcon'>
                            <img width="64" height="64" src="https://img.icons8.com/ios/64/ff5722/alarm--v1.png" alt="alarm--v1"/>
                            </div>
                            <div className='dInfo'>
                              <p>Allow admission <b>3 month free</b> subscription free in support india apps</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} lg={3}>
                        <div className='danveerItem'>
                            <div className='dIcon'>
                            <img width="64" height="64" src="https://img.icons8.com/ios/64/ff5722/upload-to-cloud--v1.png" alt="upload-to-cloud--v1"/>
                            </div>
                            <div className='dInfo'>
                              <p>Upload video income gain</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} lg={3}>
                        <div className='danveerItem'>
                            <div className='dIcon'>
                            <img width="64" height="64" src="https://img.icons8.com/ios/64/ff5722/portfolio.png" alt="portfolio"/>
                            </div>
                            <div className='dInfo'>
                              <p>Self employment proposal</p>
                            </div>
                        </div>
                    </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className='whatDo'>
                    <Row className='align-items-center'>
                      <Col sm={6}>
                        <div className='whatDoContent'>
                            <b>Create all types of videos without the fuss</b>

                            <h2>What do you have to do</h2>

                            <ul>
                              <li>Make short video</li>
                              <li>collect like your video / shorts</li>
                            </ul>

                            <Link to={''} class="btn primary-btn" title="Play Now">Let's Start</Link>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className='whatDoImg'>
                          <img src='https://www.visme.co/wp-content/uploads/2024/01/types-of-videos-1024x828.png' loading='lazy' className='img-fluid' alt=''/>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <p className='sectionBreak'><span>Prize for winner</span></p>
                </Col>

                <Col sm={12} md={6}></Col>
                <Col sm={12} md={6}>
                  <div className='prizeInfo'>
                    <h2>Exclusive Rewards for the Top Performer</h2>

                    <ul>
                      <li>1st top <b>100 person, sort by maximum like</b> will get 1 lakh</li>
                      <li>2nd top <b>1000 person, sort by maximum like</b> will get sewing machine per person</li>
                      <li>3rd top <b>5000 person, sort by maximum like</b> will get cottage industry material</li>
                    </ul>

                    <div className='prizeBtnWrap'>
                      
                      <Link to={''} class="btn primary-btn" title="Play Now">MY DANVEER GROUP</Link>
                      <Link to={''} class="btn primary-btn" title="Play Now">MY REWARD</Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
        </section>
    </>
  )
}
