import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import { FaPlay } from "react-icons/fa";
import placeholder from '../../images/placeholder.svg';

export default function Myplaylist(props) {
    return (
        <>
            
                <div className={props.classcss}>
                <Link to={props.podcustLink} className="playItem">
                    <div className="playOverley"><FaPlay />Play All</div>
                    <div  className="playImg">
                        


                        <LazyLoadImage title={props.podcastName} className="img-fluid" src={props.podcastThumb}
                                                    placeholderSrc={placeholder}
                                                    alt={props.podcastName}
                                                    effect="blur"
                                                    wrapperProps={{
                                                        // If you need to, you can tweak the effect transition using the wrapper style.
                                                        style: { transitionDelay: "1s" },
                                                    }}

                                                />



                        <div className="episodeNo">{props.episodeNo} Episodes</div>
                    </div>
                    <div className="playInfo">
                        <b>{props.podcastName}</b>
                        <p>View full podcast</p>
                    </div>
                </Link>
            
            </div>
        </>

    )

}