import React from 'react';

import {Link} from 'react-router-dom'


import catbg from '../../images/catbg.jpg';
import Breadcumb from '../../components/breadcumb/Breadcumb';
import { Col, Row } from 'react-bootstrap';
import SectionTitle from '../../includes/sectionTitle/SectionTitle';
var sectionStyle = {
  width: "100%",
  height: "100%",
  backgroundImage: "url(https://supportindiaapk.s3.ap-south-1.amazonaws.com/4A5.gif)"
};


export default function Appdownload() {
    //const {catName} = useParams();

  return (
    <>
        <section className='catBanner' style={{backgroundImage: `url(${catbg})`}}>
          <div className='container-fluid'>
            <Row>
              <Col sm={12}><Breadcumb pageTitle={"App Download"} /></Col>
            </Row>
          </div>
        </section>

        <section className='aboutvijaypath'>
            <div className='container'>
                <Row>
                    <Col sm={12} className='text-center'  style={ sectionStyle }>
                        <SectionTitle stitle="Welcome to Support India"/>
                        
<img src="https://supportindiaapk.s3.ap-south-1.amazonaws.com/qrcode.png" style={{ maxWidth: 300 }} /><br/><br/><br/>
                        <p className='mb-4 primary-btn'><b>Click the Link to download App : <Link to="https://supportindiaapk.s3.ap-south-1.amazonaws.com/supportindia.apk" target='_blank'>https://supportindiaapk.s3.ap-south-1.amazonaws.com/supportindia.apk</Link></b></p>

                        
                    </Col>
                </Row>
            </div>
        </section>
    </>
  )
}
