import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export default function Breadcumb(props) {
  return (
    <>
        <h1 style={{textTransform: "capitalize"}}>{props.pageTitle}</h1>
        <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>{props.pageTitle}</Breadcrumb.Item>
        </Breadcrumb>
    </>
  )
}
